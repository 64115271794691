body.single-project {

	article.wysiwyg {
		overflow: hidden;

		@media (max-width: $row-width) {
			margin: 0 1rem;
		}
	}

	section.primary {
		display: flex;
		flex-wrap: wrap;
		margin-top: 2rem;

		.project-description {
			flex-basis: 40rem;
			flex-grow: 1;
			border-right: 1px solid #BEB7A8;
			padding-right: 2rem;
			margin-right: 2rem;

			p {
				color: #645c4a;
				line-height: 1.8rem;
				margin-bottom: 2rem;

				@media (max-width: 910px) {
					border: none;
				}
			}
		}

		.first-arch {
			flex-basis: 15rem;
			flex-grow: 1;

			img {
				width: 100%;
				max-width: 100%;
			}
		}
	}

	img.architectural-drawing {
		width: 100%;
		margin: 1rem 0;
	}

	.gallery-row {

		display: flex;
		flex-wrap: wrap;
		justify-content: center;
		margin: 1rem -1rem;
		overflow: hidden;

		.gallery-image {
			height: 20rem;
			margin: 1rem;
			background-repeat: no-repeat;
			background-size: cover;
			background-position: center;
			flex-basis: 18rem;
			flex-grow: 1;
			max-width: 360px;
		}
	}
}