.gecko-header-hero {
    .swiper-slide {
        position: relative;

        height: 60vh;

        &__image {
            position: absolute;
            z-index: 20;
            left: 0;
            top: 0;

            width: 100%;
            height: 100%;

            background-size: cover;
            background-position: center;
            background-repeat: no-repeat;
        }
    }

    &--short .swiper-slide {
        height: 33vh;
        min-height: 140px;

        @media (max-width: 680px) {
            height: 25vh;
        }
        @media (max-width: 540px) {
            height: 20vh;
        }
    }
    &--normal .swiper-slide {
        height: 60vh;
        min-height: 300px;

        @media (max-width: 1024px) {
            height: 50vh;
        }
        @media (max-width: 680px) {
            height: 40vh;
        }
        @media (max-width: 540px) {
            height: 30vh;
        }
    }
    &--full .swiper-slide {
        height: calc(100vh - 110px);

        @media (max-width: 960px) {
            height: calc(100vh - 80px);
        }
    }

    .swiper-button-prev,
    .swiper-button-next {
        top: 0;

        display: flex;
        align-items: center;
        justify-content: center;
        margin: 0;
        width: 5rem;
        height: 100%;

        opacity: 0;
        background-image: none;
        transition: all 0.3s ease-in-out;

        &:before {
            font-family: fontawesome;
            color: #fff;
            font-size: 4rem;
            text-shadow: 0 1px 2px rgba(#000, 0.25);
            -webkit-font-smoothing: antialiased;
        }

        &:hover {
            background-color: rgba(#000, 0.125);
        }
    }
    &:hover .swiper-button-prev,
    &:hover .swiper-button-next {
        opacity: 1;
    }
    .swiper-button-prev {
        left: 0;
        transform: translateX(-100%);

        &:before {
            content: '\f104';
        }
    }
    &:hover .swiper-button-prev { transform: translateX(0); }

    .swiper-button-next {
        right: 0;
        transform: translateX(100%);

        &:before {
            content: '\f105';
        }
    }
    &:hover .swiper-button-next { transform: translateX(0); }

    .swiper-pagination-bullet {
        width: 1.5rem;
        height: 0.325rem;

        border-radius: 0;

        background-color: rgba(#FFF, 0.5);
        opacity: 1;

        &-active {
            background-color: $color-red;
        }
    }
}


.gecko-header-hero-image {
    position: relative;

    &--short {
        height: 33vh;
        min-height: 140px;

        @media (max-width: 680px) {
            height: 25vh;
        }
        @media (max-width: 540px) {
            height: 20vh;
        }
    }
    &--normal {
        height: 60vh;
        min-height: 300px;

        @media (max-width: 1024px) {
            height: 50vh;
        }
        @media (max-width: 680px) {
            height: 40vh;
        }
        @media (max-width: 540px) {
            height: 30vh;
        }
    }
    &--full {
        height: calc(100vh - 110px);

        @media (max-width: 960px) {
            height: calc(100vh - 80px);
        }
    }

    &__image {
        position: absolute;
        left: 0;
        top: 0;

        display: block;
        width: 100%;
        height: 100%;

        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
    }
}