section.testimonial {
	position: relative;
	padding: 0;
	background-color: #C6BFB5;
	text-align: center;
	border-bottom: solid 1.875rem #fff;

	.headshot {
		flex-basis: 8rem;
		flex-grow: 1;
		min-width: 200px;

		background-repeat: no-repeat;
		background-size: cover;
		background-position: center;
	}

	blockquote {
		color: $color-dark-brown;
		display: flex;
		justify-content: space-between;
		position: relative;

		@media (max-width: 540px) {
			flex-wrap: wrap;
			flex-direction: row-reverse;

			.headshot {
				height: 10rem;
				width: 8rem;
				flex-grow: 0;
				z-index: 10;

			}
		}

		.testimonial-text {
			text-align: right;
			flex-grow: 1;
			padding-right: 2rem;
			flex-basis: 40rem;
			position: relative;

			p {
				position: relative;
				z-index: 5;
				display: inline-block;

				&::after{
					position: absolute;
					left: 6rem;
					top: -1rem;
					display: none;
					content: " ";
					background-image: url('../images/quote-bg.png');
					background-size: 100%;
					background-repeat: no-repeat;
					width: 7rem;
					height: 7rem;
					z-index: -5;

					@media (max-width: 540px) {
						display: none;
					}
				}
			}
		}

		p {
			position: relative;
			display: inline-block;
			margin: 2rem 0 1.25rem 0.5rem;
			font-size: 1.2rem;
			line-height: 2.1rem;
			font-style: italic;
			font-weight: 300;

			&::after {
				content: "”";
			}
		}

		cite {
			display: block;
			font-family: $font-title;
			text-transform: uppercase;
			font-weight: 300;
			margin-right: 1rem;
			margin-bottom: 2rem;

			&::before {
				content: "- ";
			}
		}
	}
}

body footer {
	// font-family: $font-title;
	font-weight: 300;
	font-size: 0.9rem;
	color: #EEE;
	line-height: 1.3rem;
	background-color: #5C5D61;

	.footer-wrap {
		display: flex;
		// justify-content: space-between;
		// align-items: center;
		margin: 0 auto;
		padding: 0;
		width: 100%;
		max-width: $row-width;

		@media (max-width: #{$row-width + 2rem}) {
			padding-left: 1rem;
			padding-right: 1rem;
		}

		@media (max-width: 640px) {
			flex-direction: column;
			align-items: center;
		}

		.left-footer,
		.right-footer {
			flex-grow: 1;
			flex-basis: 14rem;
			display: flex;
			align-items: center;

			@media (max-width: 640px) {
				flex-basis: auto;
				margin: 1rem 0;
			}
		}

		.right-footer {
			flex-direction: column;
			align-items: flex-end;
			justify-content: center;

			@media (max-width: 640px) {
				margin-left: 1rem;
				text-align: center;
			}
		}

		.center-footer {
			flex-shrink: 0;
			margin: 1rem 4rem;
			width: 5rem;

			svg {
				display: block;
				width: 100%;
			}
		}

		.right-footer {
			text-align: right;
			// justify-content: flex-end;

			@media (max-width: 640px) {
				margin-left: 0rem;
				margin-right: 1rem;
				align-items: center;
				text-align: center;
			}
		}

		a {
			color: $color-turquoise;
		}
	}
}

.gecko-footer-hero {
	position: relative;

	margin-bottom: 2rem;

	overflow: hidden;

	&__blurred-image {
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);

		width: 110%;
		height: 110%;

		border: 2px solid red;

		filter: blur(12px);

		background-size: cover;
		background-repeat: no-repeat;
		background-position: center;

		&:after {
			position: absolute;
			left: 0;
			top: 0;

			display: block;
			width: 100%;
			height: 100%;

			content: "";
			background-color: rgba(#FFF, 0.75);
		}

		&--dark {
			&:after {
				background-color: rgba(#000, 0.66);
			}
		}
	}

	&__image {
		position: relative;
		z-index: 20;

		height: 75vh;
		min-height: 240px;

		background-size: contain;
		background-repeat: no-repeat;
		background-position: center;

		@media (max-width: 1200px) {
			height: 66vh;
		}
		@media (max-width: 1024px) {
			height: 50vh;
		}
		@media (max-width: 768px) {
			height: 40vh;
		}
		@media (max-width: 540px) {
			height: 33vh;
		}

		img {
			display: block;
			width: 100%;
			height: auto;
		}
	}

	&--fill &__image {
		background-size: cover;
	}
}



.gecko-footer-social {
	display: flex;
	margin: 1rem 0 0;

	.menu-item {
		display: flex;

		color: $color-blue;
		transition: all 0.1s ease-in-out;

		&:hover {
			color: lighten($color-blue, 8%);
		}

		&:not(:last-child) {
			margin: 0 1rem 0 0;
		}

		span {
			display: flex;
			align-items: center;
			justify-content: center;
			width: 2rem;
			height: 2rem;

			font-size: 1.6rem;

			&::before {
				font-family: fontawesome;
			}
		}
	}
}