/* Base Styles
 * The styles that go here should be site-global and not specific to any individual components.
 */

body {
	font-family: 'BonaNova', serif;
	font-weight: 400;
	background-color: #fff;

	@media (min-width: $break-nav-width) {
		padding-top: $page-header;
	}
}

a.button {
	padding: 1.5rem 3rem;

	color: #fff;

	font-family: $font-title;
	text-transform: uppercase;

	background-color: $color-red;
	transition: all ease 0.5s;

    @media (max-width: 720px) {
	   padding: 1.5rem;
	}

	&:hover {
		background-color: lighten($color-red, 5%);
	}
}

.bottom-buttons {
	display: flex;
	flex-wrap: wrap;
	justify-content: center;

	a {
		margin: 1rem;

		&.portfolio {
			&::before {
				content: '\f00a';
				font-family: fontawesome;
				margin-right: 0.5rem;
				color: #AE7569;
			}
		}
	}
}

.page-header {
	position: relative;
	z-index: 0;

	padding: 3rem 0 1rem;

	@media (max-width: #{$row-width + 2rem}) {
		margin-left: 1rem;
		margin-right: 1rem;
	}

	&__meta {
		font-family: $font-title;
		font-weight: 300;
		text-transform: uppercase;
	}

	h1 {
		position: relative;
		z-index: 10;

		margin-bottom: 0.5rem;

		font-family: $font-title;
		text-transform: uppercase;
	    font-weight: 300;
		font-size: 2.4rem;
		color: #5C5D61;
	}

	.title-box {
		z-index: 10;

		h2 {
			margin-bottom: 0.5rem;
			font-family: $font-title;
			text-transform: uppercase;
			font-weight: 300;
			font-size: 2.4rem;
			letter-spacing: 1px;
			color: #5C5D61;
		}

		h3 {
			font-size: 1.2rem;
			color: #5C5D61;
		}
	}
}

.wp-caption {
	text-align: center;
	font-size: 0.8em;
	line-height: 1.2em;
	color: #888;

	@media (max-width: 640px) {
		margin: 0 auto 1rem;
		max-width: 100%;
	}

	@media (min-width: 641px) {
		&.alignnone {
			margin-bottom: 1rem;
		}
		&.alignleft {
			float: left;
			margin: 0 1rem 1rem 0;
		}
		&.alignright {
			float: right;
			margin: 0 0 1rem 1rem;
		}
		&.aligncenter {
			margin: 0 auto 1rem;
		}
	}
}