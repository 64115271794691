body.post-type-archive-briggs_plans,
body.single-briggs_plans {
	main {
		.page-header {
			// color: red;

			.title-box {
				z-index: 10;

				h1 {
					width: 100%;
					text-align: right;
					font-family: $font-title;
					text-transform: uppercase;
					font-weight: 700;
					font-size: 2.4rem;
					color: #5C5D61;
					margin-bottom: 0.5rem;
					letter-spacing: 1px;
					padding: 0;
				}
			}
		}

		article.plan {
			padding: 2rem ;

			h2 {
				width: 100%;
				color: $color-dark-brown;
				text-align: center;
				font-family: $font-title;
				text-transform: uppercase;
				font-weight: 700;
				font-size: 2.5rem;
				margin-bottom: 0.5rem;
			}

			.plan-content {
				display: flex;
				flex-wrap: wrap;
				padding: 2rem 0;

				.plan-words {
					flex-basis: 30rem;
					flex-grow: 1;
					color: $color-dark-brown;

					p {
						line-height: 1.8rem;
					}
				}

				.plan-square-footage {
					flex-basis: 10rem;
					flex-grow: 1;
					margin-left: 2rem;
					padding-left: 2rem;
					border-left: 1px solid $color-dark-brown;
					color: $color-dark-brown;

					@media (max-width: 735px) {
						margin-left: 0;
						margin-top: 2rem;
					}

					h3 {
						font-weight: bold;
						margin-bottom: 1rem;
					}

					ul {
						li {
							margin-bottom: 0.5rem;
							&::before {
								content: '\f101';
								font-family: fontawesome;
								margin-right: 0.5rem;

							}
						}
					}
				}
			}

			.plan-images {

				padding: 2rem 0 ;

				 img {
					width: 100%;
					height: auto;
				}
			}
		}
	}
}