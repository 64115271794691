.gecko-posts {
    &__item {
        display: flex;
        margin: 0 0 3rem;
        padding: 1.5rem;

        transition: all 0.2s ease-in-out;

        &:hover {
            background-color: #FAFAFA;
        }

        @media (min-width: 769px) {
            padding-left: 2rem;

            border-left: 1px solid $color-blue;
        }
        @media (max-width: 768px) {
            flex-direction: column;
        }
    }

    &__content {
        flex-grow: 1;
        display: flex;
        flex-direction: column;
        justify-content: center;

        @media (max-width: 768px) {
            margin-bottom: 1rem;
        }
    }

    &__media {
        position: relative;

        flex-shrink: 0;

        min-height: 240px;

        box-shadow: 0 0 0 0 rgba($color-red, 0);
        transition: all 0.2s ease-in-out;
        background-color: rgba($color-blue, 0.1);

        @media (min-width: 769px) {
            flex-basis: 18rem;
            margin-bottom: 1rem;
            margin-left: 2rem;
        }

        &:hover {
            box-shadow: 0 0 0 0.25rem rgba($color-red, 0.325);
        }
    }

    &__image {
        position: absolute;
        left: 0;
        top: 0;

        display: block;
        width: 100%;
        height: 100%;

        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
    }

    &__title {
        display: block;
        margin: 0 0 0.75rem;

        font-size: 2.2rem;
        font-weight: 300;
        font-family: $font-title;
        text-transform: uppercase;
        color: $color-red;

        a:hover {
            color: lighten($color-red, 10%);
        }
    }

    &__meta {
        display: block;
        white-space: nowrap;
        margin: 0 0 0.75rem;

        font-size: 1.25rem;
        font-weight: 300;
        font-family: $font-title;
        text-transform: uppercase;
        color: #666;
    }

    &__excerpt {
        p {
            margin: 0 0 1.5rem;

            &:last-of-type {
                margin-bottom: 0;
            }
        }

        .read-more-wrapper {
            display: block;
            margin-top: 1rem;

            a {
                color: $color-red;
                text-decoration: underline;
            }
        }
    }

    &__link {
        display: none;

        &:hover {
            text-decoration: underline;
        }
    }
}


.blog-post-meta {
    display: flex;
    margin: 0 0 1rem;

    font-family: $font-title;
    font-weight: 300;
    text-transform: uppercase;

    &__date,
    &__categories {
        display: flex;
        align-items: center;
        margin-right: 1rem;

        // border: 1px solid red;

        &:before {
            display: inline-block;
            margin-right: 0.325rem;
            transform: translateY(-2px);

            -webkit-font-smoothing: antialiased;
            font-family: fontawesome;
            font-size: 0.9em;
            color: #CCC;
        }
    }

    &__date {
        &:before {
            content: "\f073";
        }
    }

    &__categories {
        padding-left: 1rem;

        &:before {
            content: "\f02c";
        }

        .post-categories {
            display: flex;
            flex-wrap: wrap;

            li {
                display: block;

                &:not(:last-child) {
                    margin-right: 0.5rem;

                    &:after {
                        content: ",";
                    }
                }

                a {
                    color: $color-red;

                    &:hover {
                        text-decoration: underline;
                    }
                }
            }
        }
    }
}


body.single-post  {
	.content {
		display: flex;
		margin-top: 2rem;

		@media(max-width: $row-width) {
			padding: 0 2rem;
		}

		@media(max-width: 600px) {
			flex-wrap: wrap;
        }

		.featured-image {
			flex-basis: 10rem;
			flex-grow: 1;
            min-width: 15rem;

            padding-right: 2rem;
			margin-right: 2rem;

            border-right: 1px solid $color-light-brown;

			@media (max-width: 600px) {
				order: 5;
			}

			img {
				width: 100%;
				height: auto;
			}
		}

		.right-content {
			flex-basis: 30rem;
			flex-grow: 1;
			margin-bottom: 4rem;

			@media(max-width: 600px) {
				border-left: none;
				padding-left: 0;
				margin-left: 0;
				padding-top: 2rem;
			}
		}
	}

}


.gecko-page-content {
    display: flex;

    @media (max-width: 1024px) {
        flex-direction: column;
    }

    .gecko-posts {
        flex-basis: 40rem;
        flex-grow: 1;
    }

    .gecko-posts-sidebar {
        flex-basis: 20rem;
        flex-shrink: 0;

        margin-left: 1rem;
        padding-top: 1.5rem;

        @media (min-width: 1025px) {
            padding-left: 2rem;
            border-left: 1px solid $color-blue;
        }

        .widget {
            margin-bottom: 2rem;

            a:hover {
                text-decoration: underline;
            }
        }

        .widget-title {
            display: block;
            margin: 0 0 0.5rem;

            font-size: 1.6rem;
        }
    }
}



.gecko-posts-pagination {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;

    a,
    span {
        display: block;
        margin: 0.25rem;
        padding: 0.5rem 1rem;

        font-family: $font-title;
        font-weight: 300;
        font-size: 1.2rem;
        text-transform: uppercase;
        // border: 1px solid blue;
    }

    a {
        color: $color-red;

        &:hover {
            text-decoration: underline;
        }
    }

    span {
        color: rgba($color-grey, 0.5);
    }
}
