// Put SCSS for the front page here
main.front {
	margin-top: 2rem;

	background-color: #fff;

	h4 {
		z-index: 30;

		padding-bottom: 1.5rem;
		padding-right: 2rem;
		padding-left: 0.5rem;

		opacity: 0;

		color: #fff;
		font-family: $font-title;
		font-size: 1.6rem;
		font-weight: 300;
		text-shadow: 0 1px 3px rgba(#000, 0.4);
		transition: all ease 0.5s;
		text-transform: uppercase;
		text-align: right;
	}

	section.main-1 {
		display: flex;
		flex-wrap: wrap;
		justify-content: space-between;
		margin: 0 -1rem;
		overflow: hidden;

		@media (max-width: $row-width) {
			margin: 0;
		}

		.hero-image {
			height: 30rem;
			background-size: cover;
			background-position: center;
			background-repeat: no-repeat;
			flex-basis: 40rem;
			flex-grow: 1;
			max-width: 100%;
			margin: 0 1rem;
			position: relative;
			display: flex;
			align-items: flex-end;
			justify-content: flex-end;
			transition: all ease 0.5s;

			@media (max-width: $row-width) {
				margin: 0rem 1rem 1rem 1rem;
			}

			&::before {
				content: '';
				position: absolute;
				top: 0;
				left: 0;
				width: 100%;
				height: 100%;
				background-color: rgba(#000, 0.0);
				z-index: 0;
				transition: all ease 0.5s;
			}

			&:hover {
				// transform: scale(1.1);

				&::before {
					background-color: rgba(#000, 0.4);
				}

				h4 {
					opacity: 1;
				}
			}
		}

		.main-1-title {
			flex-basis: 20rem;
			flex-grow: 1;
			max-width: 100%;
			display: flex;
			flex-direction: column;
			justify-content: space-between;
			margin: -1rem 1rem;
			height: 32rem;

			.main-1-title-words {
				background-color: $color-blue;
				flex-basis: 15rem;
				flex-grow: 1;
				margin: 1rem 0;
				display: flex;
				align-items: center;
				justify-content: center;
				transition: all ease 0.5s;

				@media (max-width: $row-width) {
					margin: 1rem 0rem 0rem 0rem;
				}

				&:hover {
					background-color: darken($color-blue, 10%);
					// transform: scale(1.01);
				}

				h1 {
					font-family: $font-title;
					text-transform: uppercase;
					font-weight: 300;
					font-size: 1.8rem;
					color: $color-white;
					padding: 0 3rem;
					text-align: center;
				}

			}

			.main-1-title-image {
				flex-basis: 15rem;
				flex-grow: 1;
				background-size: cover;
				background-position: center;
				background-repeat: no-repeat;
				margin: 1rem 0;
				position: relative;
				display: flex;
				align-items: flex-end;
				justify-content: flex-end;
				transition: all ease 0.5s;


				&::before {
					content: '';
					position: absolute;
					top: 0;
					left: 0;
					width: 100%;
					height: 100%;
					background-color: rgba(#000, 0.0);
					z-index: 0;
					transition: all ease 0.5s;
				}


				&:hover {
					// transform: scale(1.01);

					&::before {
						background-color: rgba(#000, 0.4);
					}

					h4 {
						opacity: 1;
					}
				}

			}
		}
	}

	section.main-2 {
		display: flex;
		flex-wrap: wrap;
		margin: 2rem -1rem;

		@media (max-width: $row-width) {
			margin: 1rem 0rem 0rem 0rem;
			overflow: hidden;
		}

		.main-2-image {
			height: 20rem;
			flex-basis: 15rem;
			flex-grow: 1;
			background-size: cover;
			background-position: center;
			background-repeat: no-repeat;
			margin: 0 1rem;
			position: relative;
			display: flex;
			align-items: flex-end;
			justify-content: flex-end;
			transition: all ease 0.5s;

			@media (max-width: $row-width) {
				margin-bottom: 16px;
			}
 
			&::before {
				content: '';
				position: absolute;
				top: 0;
				left: 0;
				width: 100%;
				height: 100%;
				background-color: rgba(#000, 0.0);
				z-index: 0;
				transition: all ease 0.5s;
			}



			&:hover {
				// transform: scale(1.01);

				&::before {
					background-color: rgba(#000, 0.4);
				}

				h4 {
					opacity: 1;
				}
			}
		}

		.main-2-title {
			height: 22rem;
			flex-basis: 15rem;
			flex-grow: 1;
			display: flex;
			flex-direction: column;
			justify-content: space-between;
			margin: -1rem 1rem;

			
			.main-2-title-words {
				background-color: $color-light-brown;
				margin: 1rem 0;
				flex-basis: 10rem;
				display: flex;
				align-items: center;
				justify-content: center;
				transition: all ease 0.5s;

				@media (max-width: $row-width) {
					margin-bottom: 0px;
				}

				&:hover {
					background-color: darken($color-light-brown, 10%);
					// transform: scale(1.01);
				}

				h1 {
					font-family: $font-title;
					text-transform: uppercase;
					font-size: 1.8rem;
					font-weight: 300;
					color: $color-white;
					padding: 0 1rem;
					text-align: center;
				}
			}

			.main-2-title-image {
				margin: 1rem 0;
				flex-basis: 10rem;
				position: relative;
				background-size: cover;
				background-position: center;
				display: flex;
				align-items: flex-end;
				justify-content: flex-end;
				transition: all ease 0.5s;

				&::before {
					content: '';
					position: absolute;
					top: 0;
					left: 0;
					width: 100%;
					height: 100%;
					background-color: rgba(#000, 0.0);
					z-index: 0;
					transition: all ease 0.5s;
				}


				&:hover {
					// transform: scale(1.01);

					&::before {
						background-color: rgba(#000, 0.4);
					}

					h4 {
						opacity: 1;
					}
				}
			}
		}

		a.main-2-featured-project {

			height: 20rem;
			flex-basis: 30rem;
			flex-grow: 1;
			margin: 0 1rem;
			position: relative;
			background-repeat: no-repeat;
			background-size: cover;
			background-position: center;
			display: flex;
			align-items: flex-end;
			justify-content: flex-end;
			transition: all ease 0.5s;

			&::before {
				content: '';
				position: absolute;
				top: 0;
				left: 0;
				width: 100%;
				height: 100%;
				background-color: rgba(#000, 0.0);
				z-index: 0;
				transition: all ease 0.5s;
			}



			&:hover {

				// transform: scale(1.01);
				&::before {
					background-color: rgba(#000, 0.4);
				}

				h4 {
					opacity: 1;
				}
			}
		}
	}
}