body.page-template-page-profile {

	.wysiwyg {

		.content {
			display: flex;
			margin-top: 2rem;

			@media(max-width: $row-width) {
				padding: 0 2rem;
			}

			@media(max-width: 600px) {
				flex-wrap: wrap;
			}

			.featured-image {
				flex-basis: 10rem;
				flex-grow: 1;
				min-width: 15rem;

				@media(max-width: 600px) {
					order: 5;
				}

				img {
					width: 100%;
					height: auto;

					&:last-of-type {
						margin-bottom: 2rem;
					}
				}
			}

			.right-content {
				flex-basis: 30rem;
				flex-grow: 1;
				padding-left: 2rem;
				border-left: 1px solid $color-light-brown;
				margin-left: 2rem;
				margin-bottom: 4rem;

				@media(max-width: 600px) {
					border-left: none;
					padding-left: 0;
					margin-left: 0;
					padding-top: 2rem;
				}

				h1 {
					// color: $color-dark-brown;
					// font-family: $font-title;
					font-size: 1.8rem;
					margin-bottom: 1rem;
				}

				p {
					margin-top: 0;
					color: $color-dark-brown;
					line-height: 2rem;
				}

				.service-list {
					background-color: $color-turquoise;
					padding: 1rem 0;

					ul {
						display: flex;
						flex-wrap: wrap;
						li {
							color: #645c4a;
							list-style: none;
							padding: 0.5rem;
							flex-basis: 12rem;
							text-indent: -1rem;
							padding-left: 1rem;

							&::before {
								content:'\f101';
								font-family: fontawesome;
								margin-right: 0.5rem;
							}
						}
					}
				}
			}
		}

		.publications {

			h2 {
				color: $color-dark-brown;
				font-size: 1.8rem;
				font-family: $font-title;
				margin-bottom: 1rem;
				text-align: center;

				&::after {
					content: '';
					background-color: $color-light-brown ;
					height: 1px;
					width: 26rem;
					max-width: 100%;
					display: block;
					margin: 1rem auto 2rem;


				}
			}

			ul {
				display: flex;
				flex-wrap: wrap;
				justify-content: center;
				margin-bottom: 2rem;

				li {
					list-style: none;
					padding: 0.25rem 1rem;
					flex-basis: 22rem;
					text-indent: -1.2rem;
					padding-left: 1.2rem;
					line-height: 1.5rem;
					color: $color-dark-brown;

					&::before {
						content:'\f101';
						font-family: fontawesome;
						margin-right: 0.5rem;
					}

					&.dummy {
						&::before {
							display: none;
						}
					}

				}
			}
		}

		.bottom-content {
			margin-top: 4rem;
			display: flex;

			@media(max-width: $row-width) {
				padding: 0 2rem;
			}

			@media(max-width: 600px) {
				flex-wrap: wrap;
			}

			.left-content {
				flex-basis: 30rem;
				flex-grow: 1;
				padding-right: 2rem;
				border-right: 1px solid $color-light-brown;
				margin-right: 2rem;
				margin-bottom: 4rem;

				@media(max-width: 600px) {
					border-right: none;
					padding-right: 0;
					margin-right: 0;
					padding-top: 2rem;
				}

				h1 {
					color: $color-dark-brown;
					font-size: 1.8rem;
					font-family: $font-title;
					margin-bottom: 1rem;
				}

				p {
					margin-top: 0;
					color: $color-dark-brown;
					line-height: 2rem;
				}
			}

			.featured-image {
				flex-basis: 10rem;
				flex-grow: 1;
				min-width: 15rem;

				img {
					width: 100%;
					height: auto;
				}
			}
		}
	}
}