body > header.touch {
	display: none;

	@include with-touch-nav {
		display: flex;
	}

	position: fixed;
	width: 100%;
	top: 0;
	left: 0;
	z-index: 100;
	height: 5rem;
	max-width: 100%;
	justify-content: space-between;
	background-color: #FFF;
	overflow: hidden;

	box-shadow: 0 3px 8px rgba(#000, 0.1);

	// The menu toggle button
	> .toggle-touch-menu {
		flex-shrink: 0;

		display: inline-flex;
		justify-content: center;
		align-items: center;

		font-size: 2.5rem;
		padding: 0.2em;
		cursor: pointer;
		color: $color-red;

		&::before {
			font-family: FontAwesome;
			width: 1em;
			height: 1em;
			vertical-align: top;
			content: "\f0c9";
		}
	}

	> .logo {
		display: flex;
		align-items: center;
		margin: 0.5rem;
		padding: 1rem 1rem;
		max-width: 16rem;

		img {
			display: block;
			max-width: 100%;
			max-height: 100%;
		}
	}
}