body > header.desktop {
	position: fixed;
	top: 0;
	left: 0;
	z-index: 40;

	width: 100%;
	height: $page-header;

	background-color: #FFF;
	box-shadow: 0 3px 14px 0px rgba(#000, 0.2);

	@include with-touch-nav {
		display: none;
	}

	.header-wrap {
		max-width: $row-width;
		margin: 0 auto;
		display: flex;
		align-items: center;
		justify-content: space-between;
		padding: 0;

		@media(max-width: $row-width) {
			padding: 0 1rem;
		}

		.logo {
			display: flex;
			align-items: center;

			height: 76px;

			img {
				display: block;
				max-height: 100%;
				width: auto;
				height: auto;
			}
		}

		nav {

			ul {
				display: flex;

				li {
					display: block;
					// margin-left: 1.5rem;
					border-bottom: 5px solid #FFF;

					transition: border 0.1s ease-in-out;

					&.current_page_item {
						border-color: rgba($color-red, 1);
					}
					&:hover {
						border-color: rgba($color-red, 0.8);
					}

					> a {
						padding: 3rem 1rem 2.5rem;
						display: block;
						font-size: 1.1rem;
						font-family: $font-title;
						font-weight: 300;
						height: 100%;
						width: 100%;

						text-transform: lowercase;
						color: #222;
					}

					> .sub-menu {
						display: none;
					}
				}
			}
		}
	}
}