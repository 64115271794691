main {
	// padding-top: 1.75rem;
	padding-bottom: 6rem;
	background-color: #fff;

	@media (max-width: 720px) {
		padding-top: 0;
	}
}

%wysiwyg, .wysiwyg {
	h1 { font-size: 2.4rem; }

	h2 { font-size: 2.2rem; }

	h3 { font-size: 1.9rem; }

	h4 { font-size: 1.4rem; }

	h5,
	h6 { font-size: 1.2rem; }

	h1, h2, h3, h4, h5, h6 {
		margin-bottom: 1em;

		font-weight: 300;
		font-family: $font-title;
		text-transform: uppercase;
		color: $color-red;
	}

	hr {
		display: block;
		margin: 2rem 0;
		height: 0px;

		border: 0;
		border-bottom: 1px solid $color-blue;
	}

	p {
		margin: 0 0 1.5rem;
		color: $color-dark-brown;
		line-height: 1.5rem;
	}

	b, strong {
		font-weight: bold;
	}

	i, em {
		font-style: italic;
	}

	ul,
	ol {
		display: block;

		li {
			color: $color-dark-brown;
			line-height: 1.325em;
		}

		> li {
			margin: 0 0 0.5rem;
		}

		ul, ol {
			margin-bottom: 0;
			margin-top: 0.5rem;
		}
	}

	ul {
		margin: 0 0 1rem 2rem;
		list-style: disc;
	}

	ol {
		list-style: decimal;
		margin: 0 0 1rem;
		padding-left: 1.75rem;

		> li {
			padding-left: 0.25rem;
		}
	}


	img {
		display: inline-block;
		max-width: 100%;

		&.alignleft {
			float: left;
			margin-right: 2rem;
			margin-bottom: 2rem;
		}
		&.aligncenter {
			display: block;
			margin: 0 auto;
		}
		&.alignright {
			float: right;
			margin-left: 2rem;
			margin-bottom: 2rem;
		}
	}

	input[type="text"],
	input[type="tel"],
	input[type="email"],
	textarea {
		border: 1px solid black;
	}

	blockquote {
		p {
			font-size: 1.2rem;
			font-weight: bold;
			line-height: 2rem;
		}
		cite {
			font-weight: normal;
			font-style: italic;
			font-size: 1rem;
			width: 100%;
			display: inline-block;

			&::before {
				content: '- ';
				margin-left: 1rem;

			}
		}
	}

	.gform_wrapper {

		.gform_body {

			.gform_fields {
				margin: 0;
				list-style: none;

				.gfield {
					margin: 0 0 1rem 0;

					&.gform_validation_container {
						display: none;
					}

					&.gfield_error {

						label,
						.validation_message {
							color: #f00;
						}

						.ginput_container {

							input,
							textarea {
								border: solid 1px #f00;
							}
						}
					}
				}
			}
		}

		.gform_footer {

			input[type=submit] {
				outline: none;
				cursor: pointer;
			}
		}
	}
}

.right-content {
	@media (max-width: #{$row-width + 2rem}) {
		padding-left: 1rem;
		padding-right: 1rem;
	}
}