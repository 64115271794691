body.page-template-page-portfolio {

	#tabs {
		margin-top: 1rem;

		border: none;

		.ui-tabs-panel {
			padding: 0;
			// border: 1px solid red;
		}

		.ui-tabs-nav {
			border: none;
			background-color: rgba(#CCC, 0.2);
			// margin-bottom: 1rem;

			font-family: $font-title;
			text-transform: uppercase;
			font-weight: 300;
			font-size: 1.2em;
		}


		.ui-tabs-nav li {
			padding: 0;

			background-color: transparent;

			font-weight: 300;
			text-transform: lowercase;
			outline: none;
			border: none;

			a.ui-tabs-anchor {
				outline: none;
				padding: 1.5rem 1.25rem;
			}

			&.ui-state-active {
				background-color: #fff;
				border: none;
				border-radius: 0;
				margin-bottom: 0;

				a {
					color: $color-red;
				}
			}
		}

		.port-row {
			display: flex;
			flex-wrap: wrap;

			margin: 0 -0.5rem;

			.large-square {
				flex-basis: 18rem;
				flex-grow: 1;
				margin: 0.5rem;

				> a::after {
					position: absolute;
					z-index: 1;
					top: 0;
					left: 0;

					display: block;
					width: 100%;
					height: 100%;

					content: "";

					background-color: rgba(#000, 0.2);
					background-image: linear-gradient(to bottom, rgba(#000, 0) 40%, rgba(#000, 0.6) 100%);
				}

				a {
					position: relative;
					width: 100%;
					height: 21rem;
					background-size: cover;
					text-decoration: none;
					display: flex;
					flex-direction: row-reverse;
					align-items: flex-end;
					padding: 1.5rem 2rem;
					transition: all ease 0.5s;

					&:hover {
						// transform: scale(1.005);

						&::before {
							background-color: rgba(#000, 0.3);
						}
					}

					&::before {
						content: '';
						position: absolute;
						top: 0;
						left: 0;
						width: 100%;
						height: 100%;
						background-color: rgba(#000, 0.0);
						z-index: 0;
						transition: all ease 0.5s;
					}
				}
			}

			.tile-section {
				position: relative;
				flex-basis: 33rem;
				flex-grow: 1;

				display: flex;
				flex-wrap: wrap;

				> a::after {
					position: absolute;
					z-index: 1;
					top: 0;
					left: 0;

					display: block;
					width: 100%;
					height: 100%;

					content: "";

					background-color: rgba(#000, 0.2);
					background-image: linear-gradient(to bottom, rgba(#000, 0) 40%, rgba(#000, 0.6) 100%);
				}

				a {
					flex-grow: 1;

					position: relative;
					z-index: 2;

					display: flex;
					flex-direction: row-reverse;
					align-items: flex-end;
					padding: 1.5rem 2rem;
					margin: 0.5rem;
					height: 10rem;

					text-decoration: none;

					background-size: cover;
					background-position: center;

					transition: all ease 0.5s;

					&::before {
						content: '';
						position: absolute;
						top: 0;
						left: 0;
						width: 100%;
						height: 100%;
						background-color: rgba(#000, 0.0);
						z-index: 0;
						transition: all ease 0.5s;
					}

					&:hover {
						// transform: scale(1.01);

						&::before {
							background-color: rgba(#000, 0.3);
						}
					}

					&:first-child {
						flex-basis: 10rem;
					}

					&:nth-child(2) {
						flex-basis: 20rem;
					}

					&:nth-child(3) {
						flex-basis: 20rem;
					}

					&:nth-child(4) {
						flex-basis: 10rem;
					}

					&.see-all {
						flex-basis: 7rem;
						background-color: $color-red ;


						h4 {
							padding-bottom: 2rem;
							display: inline-block;
							position: relative;

							&::after {
								content: '\f178';
								font-family: fontawesome;
								position: absolute;
								bottom: 0;
								right: 0;
							}

						}
					}
				}
			}

			h4 {
				color: #fff;
				font-family: $font-title;
				text-transform: uppercase;
				text-align: right;
				font-size: 1.3rem;
				font-weight: 300;
				text-shadow: 0 1px 4px rgba(0, 0, 0, 0.8);
				line-height: 1.5rem;
				z-index: 20;
				position: relative;
			}
		}
	}
}