gecko-slideshow {
	display: block;
	position: relative;
	z-index: 1;

	&:not([data-bound]) {
		visibility: hidden;
	}

	> .viewport {
		width: 100%;
		height: 600px;

		> .slides {
			position: relative;
			width: 100%;
			height: 100%;
		}
	}

	.container {
		width: 100%;

		.slider-caption {
			width: 100%;
			max-width: $row-width;
			margin: 0 auto;
			display: flex;
			margin-top: 25%;
			justify-content: flex-end;
			font-family: $font-title;
			text-transform: uppercase;
			font-weight: 300;

			a.slide-button {
				display: flex;
				align-items: center;
				justify-content: center;
				padding: 2rem;
				margin-left: 2rem;

				background-color: $color-red;

				color: #fff;
				text-align: center;
				font-size: 1.2em;

				@media (max-width: 720px) {
					padding: 0.5rem 1rem;
				}
			}

			h1.slide-title {
				background-color: rgba(0,0,0,0.5);
				color: #fff;
				padding: 1.5rem 2rem;
				font-size: 1.5rem;
				line-height: 2rem;
				max-width: 30rem;
				text-align: right;

				@media (max-width: 720px) {
					font-size: 1rem;
					padding: 0.5rem 1rem;
					line-height: 1.5rem;
					max-width: 20rem;
					margin-right: 1rem;
				}
			}
		}
	}
}