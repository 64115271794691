body.page-template-page-contact {

	main {
		position: relative;
		padding: 0;
		margin-bottom: 2rem;
		background-size: cover;

		&:after {
			content: '';
			width: 100%;
			height: 100%;
			position: absolute;
			background-repeat: no-repeat;
			background-size: cover;
			top: 0;
			left: 0;
			background-color: rgba(#222, 0.66);
		}

		.wysiwyg {
			position: relative;
			z-index: 20;
			padding-top: 12rem;
			padding-bottom: 6rem;

			@media(max-width: 720px) {
				padding-top: 4rem;
				padding-bottom: 4rem;
			}

			h1 {
				font-family: $font-title;
				font-weight: 300;
				font-size: 3rem;
				text-align: center;
				color: #fff;
			}

			h2 {
				text-align: center;
				font-family: $font-title;
				color: #fff;
				font-size: 1.5rem;
				margin-top: 3rem;
			}

			.contact-info {
				display: flex;
				justify-content: center;
				font-family: $font-title;
				text-transform: uppercase;
				color: #fff;
				margin-top: 1rem;

				p {
					color: #fff;

					a {

					color: #fff;

						&:hover {
							color: darken(#fff, 15%);
						}
					}
				}

				.phone {
					margin-right: 2rem;
					position: relative;
					p {
						&::before {
							content: '\f095';
							font-family: fontawesome;
							color: #fff;
							margin-right: 0.5rem;
							background-color: $color-grey;
							width: 2rem;
							height: 2rem;
							padding: 0.5rem;
						}
					}
				}

				.email {
					margin-left: 2rem;

					p {
						&::before {
							content: '\f0e0';
							font-family: fontawesome;
							color: #fff;
							margin-right: 0.5rem;
							background-color: $color-grey;
							width: 2rem;
							height: 2rem;
							padding: 0.5rem;
						}
					}
				}
				
				.address {
					margin-left: 2rem;
					
					p {
						&::before {
							content: '\f041';
							font-family: fontawesome;
							color: #fff;
							margin-right: 0.5rem;
							background-color: $color-grey;
							width: 2rem;
							height: 2rem;
							padding: 0.5rem;
						}
					}
				}

			}

			form {
				max-width: 32rem;
				margin: 0 auto;
				font-family: $font-title;
				font-weight: lighter;
				padding: 2rem 1rem;

				input {
					background-color: #fff;
					border: none;
					width: 100%;
					padding: 0.5rem;
					font-weight: lighter;
					color: #000;
					font-family: sans-serif;
				}

				input[type="submit"] {
					background-color: $color-red;
					color: #fff;
					max-width: 10rem;
					padding: 2rem;
					margin: 0 auto;
					font-family: $font-title;
					text-transform: uppercase;
				}

				label {
					display: none;
				}

				textarea {
					background-color: #fff;
					border: none;
					width: 100%;
					padding: 0.5rem;
					font-family: sans-serif;
					font-weight: lighter;
				}

				ul {
					li:first-child {
						width: 50%;
						float: left;
						padding-right: 0.5rem;

						@media (max-width: 500px) {
							width: 100% ;
							padding-right: 0;
						}
					}

					li:nth-child(2) {
						width: 50%;
						float: left;
						padding-left: 0.5rem;

						@media (max-width: 500px) {
							width: 100% ;
							padding-left: 0;
						}
					}
				}

				.gform_footer {
					display: flex;
					justify-content: center;
				}
			}
		}
	}
}