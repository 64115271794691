@import url(//maxcdn.bootstrapcdn.com/font-awesome/4.6.3/css/font-awesome.min.css);
// @import url(//fonts.googleapis.com/css?family=Lora:300,400,400italic,700,700italic);
// @import url(//fonts.googleapis.com/css?family=Lato:300,400,700);

@font-face {
  font-family: 'BonaNova';
  src: url('../fonts/BonaNova-Regular.eot?#iefix') format('embedded-opentype'),  url('../fonts/BonaNova-Regular.otf')  format('opentype'),
	     url('../fonts/BonaNova-Regular.woff') format('woff'), url('../fonts/BonaNova-Regular.ttf')  format('truetype'), url('../fonts/BonaNova-Regular.svg#BonaNova') format('svg');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'BonaNova';
  src: url('../fonts/BonaNova-Bold.eot?#iefix') format('embedded-opentype'),  url('../fonts/BonaNova-Bold.otf')  format('opentype'),
	     url('../fonts/BonaNova-Bold.woff') format('woff'), url('../fonts/BonaNova-Bold.ttf')  format('truetype'), url('../fonts/BonaNova-Bold.svg#BonaNova') format('svg');
  font-weight: 700;
  font-style: normal;
}

@import url('https://fonts.googleapis.com/css?family=Josefin+Sans:300,400,700');

// Foundation's Reset
// @import "normalize" !not-sassy;

// Constants and colors
@import "constants";
@import "colors";

// Foundation
// @import "lib/foundation" !not-sassy;

// Resets
@import "lib/reset";

@import "utilities/**/*.scss";

// Baseline styling
@import "base";

// Include site components
@import "components/**/*.scss";

// Pages
@import "pages/**/*.scss";