@charset "UTF-8";
@import url(//maxcdn.bootstrapcdn.com/font-awesome/4.6.3/css/font-awesome.min.css);
@import url("https://fonts.googleapis.com/css?family=Josefin+Sans:300,400,700");
@font-face {
  font-family: 'BonaNova';
  src: url("../fonts/BonaNova-Regular.eot?#iefix") format("embedded-opentype"), url("../fonts/BonaNova-Regular.otf") format("opentype"), url("../fonts/BonaNova-Regular.woff") format("woff"), url("../fonts/BonaNova-Regular.ttf") format("truetype"), url("../fonts/BonaNova-Regular.svg#BonaNova") format("svg");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: 'BonaNova';
  src: url("../fonts/BonaNova-Bold.eot?#iefix") format("embedded-opentype"), url("../fonts/BonaNova-Bold.otf") format("opentype"), url("../fonts/BonaNova-Bold.woff") format("woff"), url("../fonts/BonaNova-Bold.ttf") format("truetype"), url("../fonts/BonaNova-Bold.svg#BonaNova") format("svg");
  font-weight: 700;
  font-style: normal; }

/**
 * Eric Meyer's Reset CSS v2.0 (http://meyerweb.com/eric/tools/css/reset/)
 * http://cssreset.com
 *
 * Improved by Gecko Designs to reset ALL the things!
 */
html, body, div, span, applet, object, iframe,
button, input, select, textarea,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary, main,
time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  background: none;
  vertical-align: baseline; }

html {
  box-sizing: border-box; }

*, *:before, *:after {
  box-sizing: inherit; }

/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section,
main {
  display: block; }

body {
  line-height: 1; }

ol, ul {
  list-style: none; }

blockquote, q {
  quotes: none; }

blockquote:before, blockquote:after,
q:before, q:after {
  content: none; }

table {
  border-collapse: collapse;
  border-spacing: 0; }

a {
  text-decoration: none;
  color: inherit; }

h1, h2, h3, h4, h5, h6 {
  text-decoration: none; }

.clamped-width {
  max-width: 71.25rem;
  margin: 0 auto; }

/* Full-width mixin (1.0.0)
 * Creates a full-width section
 * Side-effect: element must be position relative
 */
/* Grid Box (1.0.0)
 * Creates a variable-count and variable-gutter grid system.
 * Apply this class to all children and ensure that they are the only members of the grid container.
 * This system is much more flexible than Foundation, it should be used for grids instead of columns.
 */
/* Breakpoint Utilities (1.0.0)
 * Provides utilities for imlementing custom, flexible breakpoints.
 */
/* Touch Nav Detection (1.1.0)
 * Determines whether we should be using touch navigation or not.
 * Pass in 'false' to the argument if the mixin is being used in the root instead of in a block.
 */
/* Base Styles
 * The styles that go here should be site-global and not specific to any individual components.
 */
body {
  font-family: 'BonaNova', serif;
  font-weight: 400;
  background-color: #fff; }
  @media (min-width: 960px) {
    body {
      padding-top: 110px; } }

a.button {
  padding: 1.5rem 3rem;
  color: #fff;
  font-family: "Josefin Sans", sans-serif;
  text-transform: uppercase;
  background-color: #5F3D31;
  transition: all ease 0.5s; }
  @media (max-width: 720px) {
    a.button {
      padding: 1.5rem; } }
  a.button:hover {
    background-color: #70483a; }

.bottom-buttons {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -ms-flex-pack: center;
  justify-content: center; }
  .bottom-buttons a {
    margin: 1rem; }
    .bottom-buttons a.portfolio::before {
      content: '\f00a';
      font-family: fontawesome;
      margin-right: 0.5rem;
      color: #AE7569; }

.page-header {
  position: relative;
  z-index: 0;
  padding: 3rem 0 1rem; }
  @media (max-width: 73.25rem) {
    .page-header {
      margin-left: 1rem;
      margin-right: 1rem; } }
  .page-header__meta {
    font-family: "Josefin Sans", sans-serif;
    font-weight: 300;
    text-transform: uppercase; }
  .page-header h1 {
    position: relative;
    z-index: 10;
    margin-bottom: 0.5rem;
    font-family: "Josefin Sans", sans-serif;
    text-transform: uppercase;
    font-weight: 300;
    font-size: 2.4rem;
    color: #5C5D61; }
  .page-header .title-box {
    z-index: 10; }
    .page-header .title-box h2 {
      margin-bottom: 0.5rem;
      font-family: "Josefin Sans", sans-serif;
      text-transform: uppercase;
      font-weight: 300;
      font-size: 2.4rem;
      letter-spacing: 1px;
      color: #5C5D61; }
    .page-header .title-box h3 {
      font-size: 1.2rem;
      color: #5C5D61; }

.wp-caption {
  text-align: center;
  font-size: 0.8em;
  line-height: 1.2em;
  color: #888; }
  @media (max-width: 640px) {
    .wp-caption {
      margin: 0 auto 1rem;
      max-width: 100%; } }
  @media (min-width: 641px) {
    .wp-caption.alignnone {
      margin-bottom: 1rem; }
    .wp-caption.alignleft {
      float: left;
      margin: 0 1rem 1rem 0; }
    .wp-caption.alignright {
      float: right;
      margin: 0 0 1rem 1rem; }
    .wp-caption.aligncenter {
      margin: 0 auto 1rem; } }

gecko-blackout {
  display: block;
  position: fixed;
  top: 5rem;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
  transition: opacity 0.3s ease-in-out, visibility 0s linear 0.3s; }
  gecko-blackout:not([hidden]) {
    transition-delay: 0s; }
  gecko-blackout[hidden] {
    visibility: hidden;
    opacity: 0; }

gecko-slide {
  display: -ms-flexbox;
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  transition: opacity 1s ease-in-out;
  opacity: 0; }
  gecko-slide[data-state="in"] {
    z-index: 2;
    opacity: 1; }

gecko-slideshow-controls {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 5;
  color: white;
  pointer-events: none; }
  gecko-slideshow-controls > * {
    pointer-events: all; }
  gecko-slideshow-controls .dots {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-pack: center;
    justify-content: center;
    -ms-flex-align: center;
    align-items: center;
    position: absolute;
    left: 0;
    right: 0;
    top: calc(100% - 40px - 0.5rem); }
    gecko-slideshow-controls .dots > .dot {
      -ms-flex-negative: 0;
      flex-shrink: 0;
      width: 0.9rem;
      height: 0.9rem;
      margin: 0.5rem;
      cursor: pointer;
      border-radius: 100%;
      background-color: white;
      box-shadow: 1px 2px 4px 0px rgba(0, 0, 0, 0.2);
      opacity: 0.5;
      transition: opacity 0.2s ease-in-out; }
      gecko-slideshow-controls .dots > .dot[data-active] {
        opacity: 1; }
  gecko-slideshow-controls .left, gecko-slideshow-controls .right {
    position: absolute;
    transform: translateY(-50%);
    transition: transform 0.2s ease-in-out; }
  gecko-slideshow-controls .left {
    text-shadow: -1px 1px 1px black;
    left: 0;
    top: 50%; }
    gecko-slideshow-controls .left {
      display: -ms-inline-flexbox;
      display: inline-flex;
      -ms-flex-align: center;
      align-items: center;
      -ms-flex-pack: center;
      justify-content: center;
      width: 1.2em;
      height: 1.2em;
      color: white;
      font-size: 4rem; }
      gecko-slideshow-controls .left::before {
        font-family: FontAwesome;
        content: ""; }
    gecko-slideshow-controls .left:active {
      transform: translateY(-50%) translateX(-6px); }
    @media (max-width: 720px) {
      gecko-slideshow-controls .left {
        top: 25%; } }
  gecko-slideshow-controls .right {
    text-shadow: 1px 1px 1px black;
    right: 0;
    top: 50%; }
    gecko-slideshow-controls .right {
      display: -ms-inline-flexbox;
      display: inline-flex;
      -ms-flex-align: center;
      align-items: center;
      -ms-flex-pack: center;
      justify-content: center;
      width: 1.2em;
      height: 1.2em;
      color: white;
      font-size: 4rem; }
      gecko-slideshow-controls .right::before {
        font-family: FontAwesome;
        content: ""; }
    gecko-slideshow-controls .right:active {
      transform: translateY(-50%) translateX(6px); }
    @media (max-width: 720px) {
      gecko-slideshow-controls .right {
        top: 25%; } }

gecko-slideshow {
  display: block;
  position: relative;
  z-index: 1; }
  gecko-slideshow:not([data-bound]) {
    visibility: hidden; }
  gecko-slideshow > .viewport {
    width: 100%;
    height: 600px; }
    gecko-slideshow > .viewport > .slides {
      position: relative;
      width: 100%;
      height: 100%; }
  gecko-slideshow .container {
    width: 100%; }
    gecko-slideshow .container .slider-caption {
      width: 100%;
      max-width: 71.25rem;
      margin: 0 auto;
      display: -ms-flexbox;
      display: flex;
      margin-top: 25%;
      -ms-flex-pack: end;
      justify-content: flex-end;
      font-family: "Josefin Sans", sans-serif;
      text-transform: uppercase;
      font-weight: 300; }
      gecko-slideshow .container .slider-caption a.slide-button {
        display: -ms-flexbox;
        display: flex;
        -ms-flex-align: center;
        align-items: center;
        -ms-flex-pack: center;
        justify-content: center;
        padding: 2rem;
        margin-left: 2rem;
        background-color: #5F3D31;
        color: #fff;
        text-align: center;
        font-size: 1.2em; }
        @media (max-width: 720px) {
          gecko-slideshow .container .slider-caption a.slide-button {
            padding: 0.5rem 1rem; } }
      gecko-slideshow .container .slider-caption h1.slide-title {
        background-color: rgba(0, 0, 0, 0.5);
        color: #fff;
        padding: 1.5rem 2rem;
        font-size: 1.5rem;
        line-height: 2rem;
        max-width: 30rem;
        text-align: right; }
        @media (max-width: 720px) {
          gecko-slideshow .container .slider-caption h1.slide-title {
            font-size: 1rem;
            padding: 0.5rem 1rem;
            line-height: 1.5rem;
            max-width: 20rem;
            margin-right: 1rem; } }

section.testimonial {
  position: relative;
  padding: 0;
  background-color: #C6BFB5;
  text-align: center;
  border-bottom: solid 1.875rem #fff; }
  section.testimonial .headshot {
    -ms-flex-preferred-size: 8rem;
    flex-basis: 8rem;
    -ms-flex-positive: 1;
    flex-grow: 1;
    min-width: 200px;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center; }
  section.testimonial blockquote {
    color: #444;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-pack: justify;
    justify-content: space-between;
    position: relative; }
    @media (max-width: 540px) {
      section.testimonial blockquote {
        -ms-flex-wrap: wrap;
        flex-wrap: wrap;
        -ms-flex-direction: row-reverse;
        flex-direction: row-reverse; }
        section.testimonial blockquote .headshot {
          height: 10rem;
          width: 8rem;
          -ms-flex-positive: 0;
          flex-grow: 0;
          z-index: 10; } }
    section.testimonial blockquote .testimonial-text {
      text-align: right;
      -ms-flex-positive: 1;
      flex-grow: 1;
      padding-right: 2rem;
      -ms-flex-preferred-size: 40rem;
      flex-basis: 40rem;
      position: relative; }
      section.testimonial blockquote .testimonial-text p {
        position: relative;
        z-index: 5;
        display: inline-block; }
        section.testimonial blockquote .testimonial-text p::after {
          position: absolute;
          left: 6rem;
          top: -1rem;
          display: none;
          content: " ";
          background-image: url("../images/quote-bg.png");
          background-size: 100%;
          background-repeat: no-repeat;
          width: 7rem;
          height: 7rem;
          z-index: -5; }
          @media (max-width: 540px) {
            section.testimonial blockquote .testimonial-text p::after {
              display: none; } }
    section.testimonial blockquote p {
      position: relative;
      display: inline-block;
      margin: 2rem 0 1.25rem 0.5rem;
      font-size: 1.2rem;
      line-height: 2.1rem;
      font-style: italic;
      font-weight: 300; }
      section.testimonial blockquote p::after {
        content: "”"; }
    section.testimonial blockquote cite {
      display: block;
      font-family: "Josefin Sans", sans-serif;
      text-transform: uppercase;
      font-weight: 300;
      margin-right: 1rem;
      margin-bottom: 2rem; }
      section.testimonial blockquote cite::before {
        content: "- "; }

body footer {
  font-weight: 300;
  font-size: 0.9rem;
  color: #EEE;
  line-height: 1.3rem;
  background-color: #5C5D61; }
  body footer .footer-wrap {
    display: -ms-flexbox;
    display: flex;
    margin: 0 auto;
    padding: 0;
    width: 100%;
    max-width: 71.25rem; }
    @media (max-width: 73.25rem) {
      body footer .footer-wrap {
        padding-left: 1rem;
        padding-right: 1rem; } }
    @media (max-width: 640px) {
      body footer .footer-wrap {
        -ms-flex-direction: column;
        flex-direction: column;
        -ms-flex-align: center;
        align-items: center; } }
    body footer .footer-wrap .left-footer,
    body footer .footer-wrap .right-footer {
      -ms-flex-positive: 1;
      flex-grow: 1;
      -ms-flex-preferred-size: 14rem;
      flex-basis: 14rem;
      display: -ms-flexbox;
      display: flex;
      -ms-flex-align: center;
      align-items: center; }
      @media (max-width: 640px) {
        body footer .footer-wrap .left-footer,
        body footer .footer-wrap .right-footer {
          -ms-flex-preferred-size: auto;
          flex-basis: auto;
          margin: 1rem 0; } }
    body footer .footer-wrap .right-footer {
      -ms-flex-direction: column;
      flex-direction: column;
      -ms-flex-align: end;
      align-items: flex-end;
      -ms-flex-pack: center;
      justify-content: center; }
      @media (max-width: 640px) {
        body footer .footer-wrap .right-footer {
          margin-left: 1rem;
          text-align: center; } }
    body footer .footer-wrap .center-footer {
      -ms-flex-negative: 0;
      flex-shrink: 0;
      margin: 1rem 4rem;
      width: 5rem; }
      body footer .footer-wrap .center-footer svg {
        display: block;
        width: 100%; }
    body footer .footer-wrap .right-footer {
      text-align: right; }
      @media (max-width: 640px) {
        body footer .footer-wrap .right-footer {
          margin-left: 0rem;
          margin-right: 1rem;
          -ms-flex-align: center;
          align-items: center;
          text-align: center; } }
    body footer .footer-wrap a {
      color: #AAA; }

.gecko-footer-hero {
  position: relative;
  margin-bottom: 2rem;
  overflow: hidden; }
  .gecko-footer-hero__blurred-image {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 110%;
    height: 110%;
    border: 2px solid red;
    filter: blur(12px);
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center; }
    .gecko-footer-hero__blurred-image:after {
      position: absolute;
      left: 0;
      top: 0;
      display: block;
      width: 100%;
      height: 100%;
      content: "";
      background-color: rgba(255, 255, 255, 0.75); }
    .gecko-footer-hero__blurred-image--dark:after {
      background-color: rgba(0, 0, 0, 0.66); }
  .gecko-footer-hero__image {
    position: relative;
    z-index: 20;
    height: 75vh;
    min-height: 240px;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center; }
    @media (max-width: 1200px) {
      .gecko-footer-hero__image {
        height: 66vh; } }
    @media (max-width: 1024px) {
      .gecko-footer-hero__image {
        height: 50vh; } }
    @media (max-width: 768px) {
      .gecko-footer-hero__image {
        height: 40vh; } }
    @media (max-width: 540px) {
      .gecko-footer-hero__image {
        height: 33vh; } }
    .gecko-footer-hero__image img {
      display: block;
      width: 100%;
      height: auto; }
  .gecko-footer-hero--fill .gecko-footer-hero__image {
    background-size: cover; }

.gecko-footer-social {
  display: -ms-flexbox;
  display: flex;
  margin: 1rem 0 0; }
  .gecko-footer-social .menu-item {
    display: -ms-flexbox;
    display: flex;
    color: #AAA;
    transition: all 0.1s ease-in-out; }
    .gecko-footer-social .menu-item:hover {
      color: #bebebe; }
    .gecko-footer-social .menu-item:not(:last-child) {
      margin: 0 1rem 0 0; }
    .gecko-footer-social .menu-item span {
      display: -ms-flexbox;
      display: flex;
      -ms-flex-align: center;
      align-items: center;
      -ms-flex-pack: center;
      justify-content: center;
      width: 2rem;
      height: 2rem;
      font-size: 1.6rem; }
      .gecko-footer-social .menu-item span::before {
        font-family: fontawesome; }

body > header.desktop {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 40;
  width: 100%;
  height: 110px;
  background-color: #FFF;
  box-shadow: 0 3px 14px 0px rgba(0, 0, 0, 0.2); }
  @media screen and (max-width: 960px) {
    body > header.desktop {
      display: none; } }
  html.touch body > header.desktop {
    display: none; }
  body > header.desktop .header-wrap {
    max-width: 71.25rem;
    margin: 0 auto;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
    align-items: center;
    -ms-flex-pack: justify;
    justify-content: space-between;
    padding: 0; }
    @media (max-width: 71.25rem) {
      body > header.desktop .header-wrap {
        padding: 0 1rem; } }
    body > header.desktop .header-wrap .logo {
      display: -ms-flexbox;
      display: flex;
      -ms-flex-align: center;
      align-items: center;
      height: 76px; }
      body > header.desktop .header-wrap .logo img {
        display: block;
        max-height: 100%;
        width: auto;
        height: auto; }
    body > header.desktop .header-wrap nav ul {
      display: -ms-flexbox;
      display: flex; }
      body > header.desktop .header-wrap nav ul li {
        display: block;
        border-bottom: 5px solid #FFF;
        transition: border 0.1s ease-in-out; }
        body > header.desktop .header-wrap nav ul li.current_page_item {
          border-color: #5f3d31; }
        body > header.desktop .header-wrap nav ul li:hover {
          border-color: rgba(95, 61, 49, 0.8); }
        body > header.desktop .header-wrap nav ul li > a {
          padding: 3rem 1rem 2.5rem;
          display: block;
          font-size: 1.1rem;
          font-family: "Josefin Sans", sans-serif;
          font-weight: 300;
          height: 100%;
          width: 100%;
          text-transform: lowercase;
          color: #222; }
        body > header.desktop .header-wrap nav ul li > .sub-menu {
          display: none; }

.gecko-header-hero .swiper-slide {
  position: relative;
  height: 60vh; }
  .gecko-header-hero .swiper-slide__image {
    position: absolute;
    z-index: 20;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat; }

.gecko-header-hero--short .swiper-slide {
  height: 33vh;
  min-height: 140px; }
  @media (max-width: 680px) {
    .gecko-header-hero--short .swiper-slide {
      height: 25vh; } }
  @media (max-width: 540px) {
    .gecko-header-hero--short .swiper-slide {
      height: 20vh; } }

.gecko-header-hero--normal .swiper-slide {
  height: 60vh;
  min-height: 300px; }
  @media (max-width: 1024px) {
    .gecko-header-hero--normal .swiper-slide {
      height: 50vh; } }
  @media (max-width: 680px) {
    .gecko-header-hero--normal .swiper-slide {
      height: 40vh; } }
  @media (max-width: 540px) {
    .gecko-header-hero--normal .swiper-slide {
      height: 30vh; } }

.gecko-header-hero--full .swiper-slide {
  height: calc(100vh - 110px); }
  @media (max-width: 960px) {
    .gecko-header-hero--full .swiper-slide {
      height: calc(100vh - 80px); } }

.gecko-header-hero .swiper-button-prev,
.gecko-header-hero .swiper-button-next {
  top: 0;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-pack: center;
  justify-content: center;
  margin: 0;
  width: 5rem;
  height: 100%;
  opacity: 0;
  background-image: none;
  transition: all 0.3s ease-in-out; }
  .gecko-header-hero .swiper-button-prev:before,
  .gecko-header-hero .swiper-button-next:before {
    font-family: fontawesome;
    color: #fff;
    font-size: 4rem;
    text-shadow: 0 1px 2px rgba(0, 0, 0, 0.25);
    -webkit-font-smoothing: antialiased; }
  .gecko-header-hero .swiper-button-prev:hover,
  .gecko-header-hero .swiper-button-next:hover {
    background-color: rgba(0, 0, 0, 0.125); }

.gecko-header-hero:hover .swiper-button-prev,
.gecko-header-hero:hover .swiper-button-next {
  opacity: 1; }

.gecko-header-hero .swiper-button-prev {
  left: 0;
  transform: translateX(-100%); }
  .gecko-header-hero .swiper-button-prev:before {
    content: '\f104'; }

.gecko-header-hero:hover .swiper-button-prev {
  transform: translateX(0); }

.gecko-header-hero .swiper-button-next {
  right: 0;
  transform: translateX(100%); }
  .gecko-header-hero .swiper-button-next:before {
    content: '\f105'; }

.gecko-header-hero:hover .swiper-button-next {
  transform: translateX(0); }

.gecko-header-hero .swiper-pagination-bullet {
  width: 1.5rem;
  height: 0.325rem;
  border-radius: 0;
  background-color: rgba(255, 255, 255, 0.5);
  opacity: 1; }
  .gecko-header-hero .swiper-pagination-bullet-active {
    background-color: #5F3D31; }

.gecko-header-hero-image {
  position: relative; }
  .gecko-header-hero-image--short {
    height: 33vh;
    min-height: 140px; }
    @media (max-width: 680px) {
      .gecko-header-hero-image--short {
        height: 25vh; } }
    @media (max-width: 540px) {
      .gecko-header-hero-image--short {
        height: 20vh; } }
  .gecko-header-hero-image--normal {
    height: 60vh;
    min-height: 300px; }
    @media (max-width: 1024px) {
      .gecko-header-hero-image--normal {
        height: 50vh; } }
    @media (max-width: 680px) {
      .gecko-header-hero-image--normal {
        height: 40vh; } }
    @media (max-width: 540px) {
      .gecko-header-hero-image--normal {
        height: 30vh; } }
  .gecko-header-hero-image--full {
    height: calc(100vh - 110px); }
    @media (max-width: 960px) {
      .gecko-header-hero-image--full {
        height: calc(100vh - 80px); } }
  .gecko-header-hero-image__image {
    position: absolute;
    left: 0;
    top: 0;
    display: block;
    width: 100%;
    height: 100%;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover; }

body > header.touch {
  display: none;
  position: fixed;
  width: 100%;
  top: 0;
  left: 0;
  z-index: 100;
  height: 5rem;
  max-width: 100%;
  -ms-flex-pack: justify;
  justify-content: space-between;
  background-color: #FFF;
  overflow: hidden;
  box-shadow: 0 3px 8px rgba(0, 0, 0, 0.1); }
  @media screen and (max-width: 960px) {
    body > header.touch {
      display: -ms-flexbox;
      display: flex; } }
  html.touch body > header.touch {
    display: -ms-flexbox;
    display: flex; }
  body > header.touch > .toggle-touch-menu {
    -ms-flex-negative: 0;
    flex-shrink: 0;
    display: -ms-inline-flexbox;
    display: inline-flex;
    -ms-flex-pack: center;
    justify-content: center;
    -ms-flex-align: center;
    align-items: center;
    font-size: 2.5rem;
    padding: 0.2em;
    cursor: pointer;
    color: #5F3D31; }
    body > header.touch > .toggle-touch-menu::before {
      font-family: FontAwesome;
      width: 1em;
      height: 1em;
      vertical-align: top;
      content: "\f0c9"; }
  body > header.touch > .logo {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
    align-items: center;
    margin: 0.5rem;
    padding: 1rem 1rem;
    max-width: 16rem; }
    body > header.touch > .logo img {
      display: block;
      max-width: 100%;
      max-height: 100%; }

body > aside.touch-menu {
  position: fixed;
  top: 5rem;
  left: 0;
  z-index: 105;
  width: 80vw;
  max-width: 25rem;
  height: 100vh;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  background-color: white;
  transition: left 0.3s ease-in-out; }
  body > aside.touch-menu[hidden] {
    left: -100%; }
  body > aside.touch-menu header {
    -ms-flex-negative: 0;
    flex-shrink: 0;
    padding: 1rem;
    border-bottom: 1px solid black;
    display: none; }
  body > aside.touch-menu nav {
    -ms-flex-positive: 1;
    flex-grow: 1;
    overflow-y: auto; }
    body > aside.touch-menu nav li {
      display: block; }
      body > aside.touch-menu nav li a {
        display: block;
        padding: 1rem;
        border-bottom: 1px solid rgba(0, 0, 0, 0.3);
        transition: background-color 0.2s ease-in-out; }
        body > aside.touch-menu nav li a:focus, body > aside.touch-menu nav li a:hover {
          background-color: rgba(0, 0, 0, 0.2); }
        body > aside.touch-menu nav li a:active {
          background-color: rgba(0, 0, 0, 0.3); }
  body > aside.touch-menu footer {
    -ms-flex-negative: 0;
    flex-shrink: 0;
    border-top: 1px solid black;
    padding: 1rem;
    display: none; }

main {
  padding-bottom: 6rem;
  background-color: #fff; }
  @media (max-width: 720px) {
    main {
      padding-top: 0; } }

.wysiwyg h1 {
  font-size: 2.4rem; }

.wysiwyg h2 {
  font-size: 2.2rem; }

.wysiwyg h3 {
  font-size: 1.9rem; }

.wysiwyg h4 {
  font-size: 1.4rem; }

.wysiwyg h5,
.wysiwyg h6 {
  font-size: 1.2rem; }

.wysiwyg h1, .wysiwyg h2, .wysiwyg h3, .wysiwyg h4, .wysiwyg h5, .wysiwyg h6 {
  margin-bottom: 1em;
  font-weight: 300;
  font-family: "Josefin Sans", sans-serif;
  text-transform: uppercase;
  color: #5F3D31; }

.wysiwyg hr {
  display: block;
  margin: 2rem 0;
  height: 0px;
  border: 0;
  border-bottom: 1px solid #AAA; }

.wysiwyg p {
  margin: 0 0 1.5rem;
  color: #444;
  line-height: 1.5rem; }

.wysiwyg b, .wysiwyg strong {
  font-weight: bold; }

.wysiwyg i, .wysiwyg em {
  font-style: italic; }

.wysiwyg ul,
.wysiwyg ol {
  display: block; }
  .wysiwyg ul li,
  .wysiwyg ol li {
    color: #444;
    line-height: 1.325em; }
  .wysiwyg ul > li,
  .wysiwyg ol > li {
    margin: 0 0 0.5rem; }
  .wysiwyg ul ul, .wysiwyg ul ol,
  .wysiwyg ol ul,
  .wysiwyg ol ol {
    margin-bottom: 0;
    margin-top: 0.5rem; }

.wysiwyg ul {
  margin: 0 0 1rem 2rem;
  list-style: disc; }

.wysiwyg ol {
  list-style: decimal;
  margin: 0 0 1rem;
  padding-left: 1.75rem; }
  .wysiwyg ol > li {
    padding-left: 0.25rem; }

.wysiwyg img {
  display: inline-block;
  max-width: 100%; }
  .wysiwyg img.alignleft {
    float: left;
    margin-right: 2rem;
    margin-bottom: 2rem; }
  .wysiwyg img.aligncenter {
    display: block;
    margin: 0 auto; }
  .wysiwyg img.alignright {
    float: right;
    margin-left: 2rem;
    margin-bottom: 2rem; }

.wysiwyg input[type="text"],
.wysiwyg input[type="tel"],
.wysiwyg input[type="email"],
.wysiwyg textarea {
  border: 1px solid black; }

.wysiwyg blockquote p {
  font-size: 1.2rem;
  font-weight: bold;
  line-height: 2rem; }

.wysiwyg blockquote cite {
  font-weight: normal;
  font-style: italic;
  font-size: 1rem;
  width: 100%;
  display: inline-block; }
  .wysiwyg blockquote cite::before {
    content: '- ';
    margin-left: 1rem; }

.wysiwyg .gform_wrapper .gform_body .gform_fields {
  margin: 0;
  list-style: none; }
  .wysiwyg .gform_wrapper .gform_body .gform_fields .gfield {
    margin: 0 0 1rem 0; }
    .wysiwyg .gform_wrapper .gform_body .gform_fields .gfield.gform_validation_container {
      display: none; }
    .wysiwyg .gform_wrapper .gform_body .gform_fields .gfield.gfield_error label,
    .wysiwyg .gform_wrapper .gform_body .gform_fields .gfield.gfield_error .validation_message {
      color: #f00; }
    .wysiwyg .gform_wrapper .gform_body .gform_fields .gfield.gfield_error .ginput_container input,
    .wysiwyg .gform_wrapper .gform_body .gform_fields .gfield.gfield_error .ginput_container textarea {
      border: solid 1px #f00; }

.wysiwyg .gform_wrapper .gform_footer input[type=submit] {
  outline: none;
  cursor: pointer; }

@media (max-width: 73.25rem) {
  .right-content {
    padding-left: 1rem;
    padding-right: 1rem; } }

body.page-template-page-contact main {
  position: relative;
  padding: 0;
  margin-bottom: 2rem;
  background-size: cover; }
  body.page-template-page-contact main:after {
    content: '';
    width: 100%;
    height: 100%;
    position: absolute;
    background-repeat: no-repeat;
    background-size: cover;
    top: 0;
    left: 0;
    background-color: rgba(34, 34, 34, 0.66); }
  body.page-template-page-contact main .wysiwyg {
    position: relative;
    z-index: 20;
    padding-top: 12rem;
    padding-bottom: 6rem; }
    @media (max-width: 720px) {
      body.page-template-page-contact main .wysiwyg {
        padding-top: 4rem;
        padding-bottom: 4rem; } }
    body.page-template-page-contact main .wysiwyg h1 {
      font-family: "Josefin Sans", sans-serif;
      font-weight: 300;
      font-size: 3rem;
      text-align: center;
      color: #fff; }
    body.page-template-page-contact main .wysiwyg h2 {
      text-align: center;
      font-family: "Josefin Sans", sans-serif;
      color: #fff;
      font-size: 1.5rem;
      margin-top: 3rem; }
    body.page-template-page-contact main .wysiwyg .contact-info {
      display: -ms-flexbox;
      display: flex;
      -ms-flex-pack: center;
      justify-content: center;
      font-family: "Josefin Sans", sans-serif;
      text-transform: uppercase;
      color: #fff;
      margin-top: 1rem; }
      body.page-template-page-contact main .wysiwyg .contact-info p {
        color: #fff; }
        body.page-template-page-contact main .wysiwyg .contact-info p a {
          color: #fff; }
          body.page-template-page-contact main .wysiwyg .contact-info p a:hover {
            color: #d9d9d9; }
      body.page-template-page-contact main .wysiwyg .contact-info .phone {
        margin-right: 2rem;
        position: relative; }
        body.page-template-page-contact main .wysiwyg .contact-info .phone p::before {
          content: '\f095';
          font-family: fontawesome;
          color: #fff;
          margin-right: 0.5rem;
          background-color: #424242;
          width: 2rem;
          height: 2rem;
          padding: 0.5rem; }
      body.page-template-page-contact main .wysiwyg .contact-info .email {
        margin-left: 2rem; }
        body.page-template-page-contact main .wysiwyg .contact-info .email p::before {
          content: '\f0e0';
          font-family: fontawesome;
          color: #fff;
          margin-right: 0.5rem;
          background-color: #424242;
          width: 2rem;
          height: 2rem;
          padding: 0.5rem; }
      body.page-template-page-contact main .wysiwyg .contact-info .address {
        margin-left: 2rem; }
        body.page-template-page-contact main .wysiwyg .contact-info .address p::before {
          content: '\f041';
          font-family: fontawesome;
          color: #fff;
          margin-right: 0.5rem;
          background-color: #424242;
          width: 2rem;
          height: 2rem;
          padding: 0.5rem; }
    body.page-template-page-contact main .wysiwyg form {
      max-width: 32rem;
      margin: 0 auto;
      font-family: "Josefin Sans", sans-serif;
      font-weight: lighter;
      padding: 2rem 1rem; }
      body.page-template-page-contact main .wysiwyg form input {
        background-color: #fff;
        border: none;
        width: 100%;
        padding: 0.5rem;
        font-weight: lighter;
        color: #000;
        font-family: sans-serif; }
      body.page-template-page-contact main .wysiwyg form input[type="submit"] {
        background-color: #5F3D31;
        color: #fff;
        max-width: 10rem;
        padding: 2rem;
        margin: 0 auto;
        font-family: "Josefin Sans", sans-serif;
        text-transform: uppercase; }
      body.page-template-page-contact main .wysiwyg form label {
        display: none; }
      body.page-template-page-contact main .wysiwyg form textarea {
        background-color: #fff;
        border: none;
        width: 100%;
        padding: 0.5rem;
        font-family: sans-serif;
        font-weight: lighter; }
      body.page-template-page-contact main .wysiwyg form ul li:first-child {
        width: 50%;
        float: left;
        padding-right: 0.5rem; }
        @media (max-width: 500px) {
          body.page-template-page-contact main .wysiwyg form ul li:first-child {
            width: 100%;
            padding-right: 0; } }
      body.page-template-page-contact main .wysiwyg form ul li:nth-child(2) {
        width: 50%;
        float: left;
        padding-left: 0.5rem; }
        @media (max-width: 500px) {
          body.page-template-page-contact main .wysiwyg form ul li:nth-child(2) {
            width: 100%;
            padding-left: 0; } }
      body.page-template-page-contact main .wysiwyg form .gform_footer {
        display: -ms-flexbox;
        display: flex;
        -ms-flex-pack: center;
        justify-content: center; }

main.front {
  margin-top: 2rem;
  background-color: #fff; }
  main.front h4 {
    z-index: 30;
    padding-bottom: 1.5rem;
    padding-right: 2rem;
    padding-left: 0.5rem;
    opacity: 0;
    color: #fff;
    font-family: "Josefin Sans", sans-serif;
    font-size: 1.6rem;
    font-weight: 300;
    text-shadow: 0 1px 3px rgba(0, 0, 0, 0.4);
    transition: all ease 0.5s;
    text-transform: uppercase;
    text-align: right; }
  main.front section.main-1 {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -ms-flex-pack: justify;
    justify-content: space-between;
    margin: 0 -1rem;
    overflow: hidden; }
    @media (max-width: 71.25rem) {
      main.front section.main-1 {
        margin: 0; } }
    main.front section.main-1 .hero-image {
      height: 30rem;
      background-size: cover;
      background-position: center;
      background-repeat: no-repeat;
      -ms-flex-preferred-size: 40rem;
      flex-basis: 40rem;
      -ms-flex-positive: 1;
      flex-grow: 1;
      max-width: 100%;
      margin: 0 1rem;
      position: relative;
      display: -ms-flexbox;
      display: flex;
      -ms-flex-align: end;
      align-items: flex-end;
      -ms-flex-pack: end;
      justify-content: flex-end;
      transition: all ease 0.5s; }
      @media (max-width: 71.25rem) {
        main.front section.main-1 .hero-image {
          margin: 0rem 1rem 1rem 1rem; } }
      main.front section.main-1 .hero-image::before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: transparent;
        z-index: 0;
        transition: all ease 0.5s; }
      main.front section.main-1 .hero-image:hover::before {
        background-color: rgba(0, 0, 0, 0.4); }
      main.front section.main-1 .hero-image:hover h4 {
        opacity: 1; }
    main.front section.main-1 .main-1-title {
      -ms-flex-preferred-size: 20rem;
      flex-basis: 20rem;
      -ms-flex-positive: 1;
      flex-grow: 1;
      max-width: 100%;
      display: -ms-flexbox;
      display: flex;
      -ms-flex-direction: column;
      flex-direction: column;
      -ms-flex-pack: justify;
      justify-content: space-between;
      margin: -1rem 1rem;
      height: 32rem; }
      main.front section.main-1 .main-1-title .main-1-title-words {
        background-color: #AAA;
        -ms-flex-preferred-size: 15rem;
        flex-basis: 15rem;
        -ms-flex-positive: 1;
        flex-grow: 1;
        margin: 1rem 0;
        display: -ms-flexbox;
        display: flex;
        -ms-flex-align: center;
        align-items: center;
        -ms-flex-pack: center;
        justify-content: center;
        transition: all ease 0.5s; }
        @media (max-width: 71.25rem) {
          main.front section.main-1 .main-1-title .main-1-title-words {
            margin: 1rem 0rem 0rem 0rem; } }
        main.front section.main-1 .main-1-title .main-1-title-words:hover {
          background-color: #919191; }
        main.front section.main-1 .main-1-title .main-1-title-words h1 {
          font-family: "Josefin Sans", sans-serif;
          text-transform: uppercase;
          font-weight: 300;
          font-size: 1.8rem;
          color: #fff;
          padding: 0 3rem;
          text-align: center; }
      main.front section.main-1 .main-1-title .main-1-title-image {
        -ms-flex-preferred-size: 15rem;
        flex-basis: 15rem;
        -ms-flex-positive: 1;
        flex-grow: 1;
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
        margin: 1rem 0;
        position: relative;
        display: -ms-flexbox;
        display: flex;
        -ms-flex-align: end;
        align-items: flex-end;
        -ms-flex-pack: end;
        justify-content: flex-end;
        transition: all ease 0.5s; }
        main.front section.main-1 .main-1-title .main-1-title-image::before {
          content: '';
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          background-color: transparent;
          z-index: 0;
          transition: all ease 0.5s; }
        main.front section.main-1 .main-1-title .main-1-title-image:hover::before {
          background-color: rgba(0, 0, 0, 0.4); }
        main.front section.main-1 .main-1-title .main-1-title-image:hover h4 {
          opacity: 1; }
  main.front section.main-2 {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    margin: 2rem -1rem; }
    @media (max-width: 71.25rem) {
      main.front section.main-2 {
        margin: 1rem 0rem 0rem 0rem;
        overflow: hidden; } }
    main.front section.main-2 .main-2-image {
      height: 20rem;
      -ms-flex-preferred-size: 15rem;
      flex-basis: 15rem;
      -ms-flex-positive: 1;
      flex-grow: 1;
      background-size: cover;
      background-position: center;
      background-repeat: no-repeat;
      margin: 0 1rem;
      position: relative;
      display: -ms-flexbox;
      display: flex;
      -ms-flex-align: end;
      align-items: flex-end;
      -ms-flex-pack: end;
      justify-content: flex-end;
      transition: all ease 0.5s; }
      @media (max-width: 71.25rem) {
        main.front section.main-2 .main-2-image {
          margin-bottom: 16px; } }
      main.front section.main-2 .main-2-image::before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: transparent;
        z-index: 0;
        transition: all ease 0.5s; }
      main.front section.main-2 .main-2-image:hover::before {
        background-color: rgba(0, 0, 0, 0.4); }
      main.front section.main-2 .main-2-image:hover h4 {
        opacity: 1; }
    main.front section.main-2 .main-2-title {
      height: 22rem;
      -ms-flex-preferred-size: 15rem;
      flex-basis: 15rem;
      -ms-flex-positive: 1;
      flex-grow: 1;
      display: -ms-flexbox;
      display: flex;
      -ms-flex-direction: column;
      flex-direction: column;
      -ms-flex-pack: justify;
      justify-content: space-between;
      margin: -1rem 1rem; }
      main.front section.main-2 .main-2-title .main-2-title-words {
        background-color: #AAA;
        margin: 1rem 0;
        -ms-flex-preferred-size: 10rem;
        flex-basis: 10rem;
        display: -ms-flexbox;
        display: flex;
        -ms-flex-align: center;
        align-items: center;
        -ms-flex-pack: center;
        justify-content: center;
        transition: all ease 0.5s; }
        @media (max-width: 71.25rem) {
          main.front section.main-2 .main-2-title .main-2-title-words {
            margin-bottom: 0px; } }
        main.front section.main-2 .main-2-title .main-2-title-words:hover {
          background-color: #919191; }
        main.front section.main-2 .main-2-title .main-2-title-words h1 {
          font-family: "Josefin Sans", sans-serif;
          text-transform: uppercase;
          font-size: 1.8rem;
          font-weight: 300;
          color: #fff;
          padding: 0 1rem;
          text-align: center; }
      main.front section.main-2 .main-2-title .main-2-title-image {
        margin: 1rem 0;
        -ms-flex-preferred-size: 10rem;
        flex-basis: 10rem;
        position: relative;
        background-size: cover;
        background-position: center;
        display: -ms-flexbox;
        display: flex;
        -ms-flex-align: end;
        align-items: flex-end;
        -ms-flex-pack: end;
        justify-content: flex-end;
        transition: all ease 0.5s; }
        main.front section.main-2 .main-2-title .main-2-title-image::before {
          content: '';
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          background-color: transparent;
          z-index: 0;
          transition: all ease 0.5s; }
        main.front section.main-2 .main-2-title .main-2-title-image:hover::before {
          background-color: rgba(0, 0, 0, 0.4); }
        main.front section.main-2 .main-2-title .main-2-title-image:hover h4 {
          opacity: 1; }
    main.front section.main-2 a.main-2-featured-project {
      height: 20rem;
      -ms-flex-preferred-size: 30rem;
      flex-basis: 30rem;
      -ms-flex-positive: 1;
      flex-grow: 1;
      margin: 0 1rem;
      position: relative;
      background-repeat: no-repeat;
      background-size: cover;
      background-position: center;
      display: -ms-flexbox;
      display: flex;
      -ms-flex-align: end;
      align-items: flex-end;
      -ms-flex-pack: end;
      justify-content: flex-end;
      transition: all ease 0.5s; }
      main.front section.main-2 a.main-2-featured-project::before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: transparent;
        z-index: 0;
        transition: all ease 0.5s; }
      main.front section.main-2 a.main-2-featured-project:hover::before {
        background-color: rgba(0, 0, 0, 0.4); }
      main.front section.main-2 a.main-2-featured-project:hover h4 {
        opacity: 1; }

.gecko-posts__item {
  display: -ms-flexbox;
  display: flex;
  margin: 0 0 3rem;
  padding: 1.5rem;
  transition: all 0.2s ease-in-out; }
  .gecko-posts__item:hover {
    background-color: #FAFAFA; }
  @media (min-width: 769px) {
    .gecko-posts__item {
      padding-left: 2rem;
      border-left: 1px solid #AAA; } }
  @media (max-width: 768px) {
    .gecko-posts__item {
      -ms-flex-direction: column;
      flex-direction: column; } }

.gecko-posts__content {
  -ms-flex-positive: 1;
  flex-grow: 1;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  -ms-flex-pack: center;
  justify-content: center; }
  @media (max-width: 768px) {
    .gecko-posts__content {
      margin-bottom: 1rem; } }

.gecko-posts__media {
  position: relative;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  min-height: 240px;
  box-shadow: 0 0 0 0 rgba(95, 61, 49, 0);
  transition: all 0.2s ease-in-out;
  background-color: rgba(170, 170, 170, 0.1); }
  @media (min-width: 769px) {
    .gecko-posts__media {
      -ms-flex-preferred-size: 18rem;
      flex-basis: 18rem;
      margin-bottom: 1rem;
      margin-left: 2rem; } }
  .gecko-posts__media:hover {
    box-shadow: 0 0 0 0.25rem rgba(95, 61, 49, 0.325); }

.gecko-posts__image {
  position: absolute;
  left: 0;
  top: 0;
  display: block;
  width: 100%;
  height: 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover; }

.gecko-posts__title {
  display: block;
  margin: 0 0 0.75rem;
  font-size: 2.2rem;
  font-weight: 300;
  font-family: "Josefin Sans", sans-serif;
  text-transform: uppercase;
  color: #5F3D31; }
  .gecko-posts__title a:hover {
    color: #815342; }

.gecko-posts__meta {
  display: block;
  white-space: nowrap;
  margin: 0 0 0.75rem;
  font-size: 1.25rem;
  font-weight: 300;
  font-family: "Josefin Sans", sans-serif;
  text-transform: uppercase;
  color: #666; }

.gecko-posts__excerpt p {
  margin: 0 0 1.5rem; }
  .gecko-posts__excerpt p:last-of-type {
    margin-bottom: 0; }

.gecko-posts__excerpt .read-more-wrapper {
  display: block;
  margin-top: 1rem; }
  .gecko-posts__excerpt .read-more-wrapper a {
    color: #5F3D31;
    text-decoration: underline; }

.gecko-posts__link {
  display: none; }
  .gecko-posts__link:hover {
    text-decoration: underline; }

.blog-post-meta {
  display: -ms-flexbox;
  display: flex;
  margin: 0 0 1rem;
  font-family: "Josefin Sans", sans-serif;
  font-weight: 300;
  text-transform: uppercase; }
  .blog-post-meta__date, .blog-post-meta__categories {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
    align-items: center;
    margin-right: 1rem; }
    .blog-post-meta__date:before, .blog-post-meta__categories:before {
      display: inline-block;
      margin-right: 0.325rem;
      transform: translateY(-2px);
      -webkit-font-smoothing: antialiased;
      font-family: fontawesome;
      font-size: 0.9em;
      color: #CCC; }
  .blog-post-meta__date:before {
    content: "\f073"; }
  .blog-post-meta__categories {
    padding-left: 1rem; }
    .blog-post-meta__categories:before {
      content: "\f02c"; }
    .blog-post-meta__categories .post-categories {
      display: -ms-flexbox;
      display: flex;
      -ms-flex-wrap: wrap;
      flex-wrap: wrap; }
      .blog-post-meta__categories .post-categories li {
        display: block; }
        .blog-post-meta__categories .post-categories li:not(:last-child) {
          margin-right: 0.5rem; }
          .blog-post-meta__categories .post-categories li:not(:last-child):after {
            content: ","; }
        .blog-post-meta__categories .post-categories li a {
          color: #5F3D31; }
          .blog-post-meta__categories .post-categories li a:hover {
            text-decoration: underline; }

body.single-post .content {
  display: -ms-flexbox;
  display: flex;
  margin-top: 2rem; }
  @media (max-width: 71.25rem) {
    body.single-post .content {
      padding: 0 2rem; } }
  @media (max-width: 600px) {
    body.single-post .content {
      -ms-flex-wrap: wrap;
      flex-wrap: wrap; } }
  body.single-post .content .featured-image {
    -ms-flex-preferred-size: 10rem;
    flex-basis: 10rem;
    -ms-flex-positive: 1;
    flex-grow: 1;
    min-width: 15rem;
    padding-right: 2rem;
    margin-right: 2rem;
    border-right: 1px solid #AAA; }
    @media (max-width: 600px) {
      body.single-post .content .featured-image {
        -ms-flex-order: 5;
        order: 5; } }
    body.single-post .content .featured-image img {
      width: 100%;
      height: auto; }
  body.single-post .content .right-content {
    -ms-flex-preferred-size: 30rem;
    flex-basis: 30rem;
    -ms-flex-positive: 1;
    flex-grow: 1;
    margin-bottom: 4rem; }
    @media (max-width: 600px) {
      body.single-post .content .right-content {
        border-left: none;
        padding-left: 0;
        margin-left: 0;
        padding-top: 2rem; } }

.gecko-page-content {
  display: -ms-flexbox;
  display: flex; }
  @media (max-width: 1024px) {
    .gecko-page-content {
      -ms-flex-direction: column;
      flex-direction: column; } }
  .gecko-page-content .gecko-posts {
    -ms-flex-preferred-size: 40rem;
    flex-basis: 40rem;
    -ms-flex-positive: 1;
    flex-grow: 1; }
  .gecko-page-content .gecko-posts-sidebar {
    -ms-flex-preferred-size: 20rem;
    flex-basis: 20rem;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    margin-left: 1rem;
    padding-top: 1.5rem; }
    @media (min-width: 1025px) {
      .gecko-page-content .gecko-posts-sidebar {
        padding-left: 2rem;
        border-left: 1px solid #AAA; } }
    .gecko-page-content .gecko-posts-sidebar .widget {
      margin-bottom: 2rem; }
      .gecko-page-content .gecko-posts-sidebar .widget a:hover {
        text-decoration: underline; }
    .gecko-page-content .gecko-posts-sidebar .widget-title {
      display: block;
      margin: 0 0 0.5rem;
      font-size: 1.6rem; }

.gecko-posts-pagination {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-pack: center;
  justify-content: center;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap; }
  .gecko-posts-pagination a,
  .gecko-posts-pagination span {
    display: block;
    margin: 0.25rem;
    padding: 0.5rem 1rem;
    font-family: "Josefin Sans", sans-serif;
    font-weight: 300;
    font-size: 1.2rem;
    text-transform: uppercase; }
  .gecko-posts-pagination a {
    color: #5F3D31; }
    .gecko-posts-pagination a:hover {
      text-decoration: underline; }
  .gecko-posts-pagination span {
    color: rgba(66, 66, 66, 0.5); }

body.page-template-page-images-sidebar .content,
body.page-template-page-philosophy .content {
  display: -ms-flexbox;
  display: flex;
  margin-top: 2rem; }
  @media (max-width: 71.25rem) {
    body.page-template-page-images-sidebar .content,
    body.page-template-page-philosophy .content {
      padding: 0 2rem; } }
  @media (max-width: 600px) {
    body.page-template-page-images-sidebar .content,
    body.page-template-page-philosophy .content {
      -ms-flex-wrap: wrap;
      flex-wrap: wrap; } }
  body.page-template-page-images-sidebar .content .featured-image,
  body.page-template-page-philosophy .content .featured-image {
    -ms-flex-preferred-size: 10rem;
    flex-basis: 10rem;
    -ms-flex-positive: 1;
    flex-grow: 1;
    min-width: 15rem; }
    @media (max-width: 600px) {
      body.page-template-page-images-sidebar .content .featured-image,
      body.page-template-page-philosophy .content .featured-image {
        -ms-flex-order: 5;
        order: 5; } }
    body.page-template-page-images-sidebar .content .featured-image img,
    body.page-template-page-philosophy .content .featured-image img {
      width: 100%;
      height: auto; }
  body.page-template-page-images-sidebar .content .right-content,
  body.page-template-page-philosophy .content .right-content {
    -ms-flex-preferred-size: 30rem;
    flex-basis: 30rem;
    -ms-flex-positive: 1;
    flex-grow: 1;
    padding-left: 2rem;
    border-left: 1px solid #AAA;
    margin-left: 2rem;
    margin-bottom: 4rem; }
    @media (max-width: 600px) {
      body.page-template-page-images-sidebar .content .right-content,
      body.page-template-page-philosophy .content .right-content {
        border-left: none;
        padding-left: 0;
        margin-left: 0;
        padding-top: 2rem; } }

body.post-type-archive-briggs_plans main .page-header .title-box,
body.single-briggs_plans main .page-header .title-box {
  z-index: 10; }
  body.post-type-archive-briggs_plans main .page-header .title-box h1,
  body.single-briggs_plans main .page-header .title-box h1 {
    width: 100%;
    text-align: right;
    font-family: "Josefin Sans", sans-serif;
    text-transform: uppercase;
    font-weight: 700;
    font-size: 2.4rem;
    color: #5C5D61;
    margin-bottom: 0.5rem;
    letter-spacing: 1px;
    padding: 0; }

body.post-type-archive-briggs_plans main article.plan,
body.single-briggs_plans main article.plan {
  padding: 2rem; }
  body.post-type-archive-briggs_plans main article.plan h2,
  body.single-briggs_plans main article.plan h2 {
    width: 100%;
    color: #444;
    text-align: center;
    font-family: "Josefin Sans", sans-serif;
    text-transform: uppercase;
    font-weight: 700;
    font-size: 2.5rem;
    margin-bottom: 0.5rem; }
  body.post-type-archive-briggs_plans main article.plan .plan-content,
  body.single-briggs_plans main article.plan .plan-content {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    padding: 2rem 0; }
    body.post-type-archive-briggs_plans main article.plan .plan-content .plan-words,
    body.single-briggs_plans main article.plan .plan-content .plan-words {
      -ms-flex-preferred-size: 30rem;
      flex-basis: 30rem;
      -ms-flex-positive: 1;
      flex-grow: 1;
      color: #444; }
      body.post-type-archive-briggs_plans main article.plan .plan-content .plan-words p,
      body.single-briggs_plans main article.plan .plan-content .plan-words p {
        line-height: 1.8rem; }
    body.post-type-archive-briggs_plans main article.plan .plan-content .plan-square-footage,
    body.single-briggs_plans main article.plan .plan-content .plan-square-footage {
      -ms-flex-preferred-size: 10rem;
      flex-basis: 10rem;
      -ms-flex-positive: 1;
      flex-grow: 1;
      margin-left: 2rem;
      padding-left: 2rem;
      border-left: 1px solid #444;
      color: #444; }
      @media (max-width: 735px) {
        body.post-type-archive-briggs_plans main article.plan .plan-content .plan-square-footage,
        body.single-briggs_plans main article.plan .plan-content .plan-square-footage {
          margin-left: 0;
          margin-top: 2rem; } }
      body.post-type-archive-briggs_plans main article.plan .plan-content .plan-square-footage h3,
      body.single-briggs_plans main article.plan .plan-content .plan-square-footage h3 {
        font-weight: bold;
        margin-bottom: 1rem; }
      body.post-type-archive-briggs_plans main article.plan .plan-content .plan-square-footage ul li,
      body.single-briggs_plans main article.plan .plan-content .plan-square-footage ul li {
        margin-bottom: 0.5rem; }
        body.post-type-archive-briggs_plans main article.plan .plan-content .plan-square-footage ul li::before,
        body.single-briggs_plans main article.plan .plan-content .plan-square-footage ul li::before {
          content: '\f101';
          font-family: fontawesome;
          margin-right: 0.5rem; }
  body.post-type-archive-briggs_plans main article.plan .plan-images,
  body.single-briggs_plans main article.plan .plan-images {
    padding: 2rem 0; }
    body.post-type-archive-briggs_plans main article.plan .plan-images img,
    body.single-briggs_plans main article.plan .plan-images img {
      width: 100%;
      height: auto; }

body.page-template-page-portfolio #tabs {
  margin-top: 1rem;
  border: none; }
  body.page-template-page-portfolio #tabs .ui-tabs-panel {
    padding: 0; }
  body.page-template-page-portfolio #tabs .ui-tabs-nav {
    border: none;
    background-color: rgba(204, 204, 204, 0.2);
    font-family: "Josefin Sans", sans-serif;
    text-transform: uppercase;
    font-weight: 300;
    font-size: 1.2em; }
  body.page-template-page-portfolio #tabs .ui-tabs-nav li {
    padding: 0;
    background-color: transparent;
    font-weight: 300;
    text-transform: lowercase;
    outline: none;
    border: none; }
    body.page-template-page-portfolio #tabs .ui-tabs-nav li a.ui-tabs-anchor {
      outline: none;
      padding: 1.5rem 1.25rem; }
    body.page-template-page-portfolio #tabs .ui-tabs-nav li.ui-state-active {
      background-color: #fff;
      border: none;
      border-radius: 0;
      margin-bottom: 0; }
      body.page-template-page-portfolio #tabs .ui-tabs-nav li.ui-state-active a {
        color: #5F3D31; }
  body.page-template-page-portfolio #tabs .port-row {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    margin: 0 -0.5rem; }
    body.page-template-page-portfolio #tabs .port-row .large-square {
      -ms-flex-preferred-size: 18rem;
      flex-basis: 18rem;
      -ms-flex-positive: 1;
      flex-grow: 1;
      margin: 0.5rem; }
      body.page-template-page-portfolio #tabs .port-row .large-square > a::after {
        position: absolute;
        z-index: 1;
        top: 0;
        left: 0;
        display: block;
        width: 100%;
        height: 100%;
        content: "";
        background-color: rgba(0, 0, 0, 0.2);
        background-image: linear-gradient(to bottom, transparent 40%, rgba(0, 0, 0, 0.6) 100%); }
      body.page-template-page-portfolio #tabs .port-row .large-square a {
        position: relative;
        width: 100%;
        height: 21rem;
        background-size: cover;
        text-decoration: none;
        display: -ms-flexbox;
        display: flex;
        -ms-flex-direction: row-reverse;
        flex-direction: row-reverse;
        -ms-flex-align: end;
        align-items: flex-end;
        padding: 1.5rem 2rem;
        transition: all ease 0.5s; }
        body.page-template-page-portfolio #tabs .port-row .large-square a:hover::before {
          background-color: rgba(0, 0, 0, 0.3); }
        body.page-template-page-portfolio #tabs .port-row .large-square a::before {
          content: '';
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          background-color: transparent;
          z-index: 0;
          transition: all ease 0.5s; }
    body.page-template-page-portfolio #tabs .port-row .tile-section {
      position: relative;
      -ms-flex-preferred-size: 33rem;
      flex-basis: 33rem;
      -ms-flex-positive: 1;
      flex-grow: 1;
      display: -ms-flexbox;
      display: flex;
      -ms-flex-wrap: wrap;
      flex-wrap: wrap; }
      body.page-template-page-portfolio #tabs .port-row .tile-section > a::after {
        position: absolute;
        z-index: 1;
        top: 0;
        left: 0;
        display: block;
        width: 100%;
        height: 100%;
        content: "";
        background-color: rgba(0, 0, 0, 0.2);
        background-image: linear-gradient(to bottom, transparent 40%, rgba(0, 0, 0, 0.6) 100%); }
      body.page-template-page-portfolio #tabs .port-row .tile-section a {
        -ms-flex-positive: 1;
        flex-grow: 1;
        position: relative;
        z-index: 2;
        display: -ms-flexbox;
        display: flex;
        -ms-flex-direction: row-reverse;
        flex-direction: row-reverse;
        -ms-flex-align: end;
        align-items: flex-end;
        padding: 1.5rem 2rem;
        margin: 0.5rem;
        height: 10rem;
        text-decoration: none;
        background-size: cover;
        background-position: center;
        transition: all ease 0.5s; }
        body.page-template-page-portfolio #tabs .port-row .tile-section a::before {
          content: '';
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          background-color: transparent;
          z-index: 0;
          transition: all ease 0.5s; }
        body.page-template-page-portfolio #tabs .port-row .tile-section a:hover::before {
          background-color: rgba(0, 0, 0, 0.3); }
        body.page-template-page-portfolio #tabs .port-row .tile-section a:first-child {
          -ms-flex-preferred-size: 10rem;
          flex-basis: 10rem; }
        body.page-template-page-portfolio #tabs .port-row .tile-section a:nth-child(2) {
          -ms-flex-preferred-size: 20rem;
          flex-basis: 20rem; }
        body.page-template-page-portfolio #tabs .port-row .tile-section a:nth-child(3) {
          -ms-flex-preferred-size: 20rem;
          flex-basis: 20rem; }
        body.page-template-page-portfolio #tabs .port-row .tile-section a:nth-child(4) {
          -ms-flex-preferred-size: 10rem;
          flex-basis: 10rem; }
        body.page-template-page-portfolio #tabs .port-row .tile-section a.see-all {
          -ms-flex-preferred-size: 7rem;
          flex-basis: 7rem;
          background-color: #5F3D31; }
          body.page-template-page-portfolio #tabs .port-row .tile-section a.see-all h4 {
            padding-bottom: 2rem;
            display: inline-block;
            position: relative; }
            body.page-template-page-portfolio #tabs .port-row .tile-section a.see-all h4::after {
              content: '\f178';
              font-family: fontawesome;
              position: absolute;
              bottom: 0;
              right: 0; }
    body.page-template-page-portfolio #tabs .port-row h4 {
      color: #fff;
      font-family: "Josefin Sans", sans-serif;
      text-transform: uppercase;
      text-align: right;
      font-size: 1.3rem;
      font-weight: 300;
      text-shadow: 0 1px 4px rgba(0, 0, 0, 0.8);
      line-height: 1.5rem;
      z-index: 20;
      position: relative; }

body.page-template-page-profile .wysiwyg .content {
  display: -ms-flexbox;
  display: flex;
  margin-top: 2rem; }
  @media (max-width: 71.25rem) {
    body.page-template-page-profile .wysiwyg .content {
      padding: 0 2rem; } }
  @media (max-width: 600px) {
    body.page-template-page-profile .wysiwyg .content {
      -ms-flex-wrap: wrap;
      flex-wrap: wrap; } }
  body.page-template-page-profile .wysiwyg .content .featured-image {
    -ms-flex-preferred-size: 10rem;
    flex-basis: 10rem;
    -ms-flex-positive: 1;
    flex-grow: 1;
    min-width: 15rem; }
    @media (max-width: 600px) {
      body.page-template-page-profile .wysiwyg .content .featured-image {
        -ms-flex-order: 5;
        order: 5; } }
    body.page-template-page-profile .wysiwyg .content .featured-image img {
      width: 100%;
      height: auto; }
      body.page-template-page-profile .wysiwyg .content .featured-image img:last-of-type {
        margin-bottom: 2rem; }
  body.page-template-page-profile .wysiwyg .content .right-content {
    -ms-flex-preferred-size: 30rem;
    flex-basis: 30rem;
    -ms-flex-positive: 1;
    flex-grow: 1;
    padding-left: 2rem;
    border-left: 1px solid #AAA;
    margin-left: 2rem;
    margin-bottom: 4rem; }
    @media (max-width: 600px) {
      body.page-template-page-profile .wysiwyg .content .right-content {
        border-left: none;
        padding-left: 0;
        margin-left: 0;
        padding-top: 2rem; } }
    body.page-template-page-profile .wysiwyg .content .right-content h1 {
      font-size: 1.8rem;
      margin-bottom: 1rem; }
    body.page-template-page-profile .wysiwyg .content .right-content p {
      margin-top: 0;
      color: #444;
      line-height: 2rem; }
    body.page-template-page-profile .wysiwyg .content .right-content .service-list {
      background-color: #AAA;
      padding: 1rem 0; }
      body.page-template-page-profile .wysiwyg .content .right-content .service-list ul {
        display: -ms-flexbox;
        display: flex;
        -ms-flex-wrap: wrap;
        flex-wrap: wrap; }
        body.page-template-page-profile .wysiwyg .content .right-content .service-list ul li {
          color: #645c4a;
          list-style: none;
          padding: 0.5rem;
          -ms-flex-preferred-size: 12rem;
          flex-basis: 12rem;
          text-indent: -1rem;
          padding-left: 1rem; }
          body.page-template-page-profile .wysiwyg .content .right-content .service-list ul li::before {
            content: '\f101';
            font-family: fontawesome;
            margin-right: 0.5rem; }

body.page-template-page-profile .wysiwyg .publications h2 {
  color: #444;
  font-size: 1.8rem;
  font-family: "Josefin Sans", sans-serif;
  margin-bottom: 1rem;
  text-align: center; }
  body.page-template-page-profile .wysiwyg .publications h2::after {
    content: '';
    background-color: #AAA;
    height: 1px;
    width: 26rem;
    max-width: 100%;
    display: block;
    margin: 1rem auto 2rem; }

body.page-template-page-profile .wysiwyg .publications ul {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -ms-flex-pack: center;
  justify-content: center;
  margin-bottom: 2rem; }
  body.page-template-page-profile .wysiwyg .publications ul li {
    list-style: none;
    padding: 0.25rem 1rem;
    -ms-flex-preferred-size: 22rem;
    flex-basis: 22rem;
    text-indent: -1.2rem;
    padding-left: 1.2rem;
    line-height: 1.5rem;
    color: #444; }
    body.page-template-page-profile .wysiwyg .publications ul li::before {
      content: '\f101';
      font-family: fontawesome;
      margin-right: 0.5rem; }
    body.page-template-page-profile .wysiwyg .publications ul li.dummy::before {
      display: none; }

body.page-template-page-profile .wysiwyg .bottom-content {
  margin-top: 4rem;
  display: -ms-flexbox;
  display: flex; }
  @media (max-width: 71.25rem) {
    body.page-template-page-profile .wysiwyg .bottom-content {
      padding: 0 2rem; } }
  @media (max-width: 600px) {
    body.page-template-page-profile .wysiwyg .bottom-content {
      -ms-flex-wrap: wrap;
      flex-wrap: wrap; } }
  body.page-template-page-profile .wysiwyg .bottom-content .left-content {
    -ms-flex-preferred-size: 30rem;
    flex-basis: 30rem;
    -ms-flex-positive: 1;
    flex-grow: 1;
    padding-right: 2rem;
    border-right: 1px solid #AAA;
    margin-right: 2rem;
    margin-bottom: 4rem; }
    @media (max-width: 600px) {
      body.page-template-page-profile .wysiwyg .bottom-content .left-content {
        border-right: none;
        padding-right: 0;
        margin-right: 0;
        padding-top: 2rem; } }
    body.page-template-page-profile .wysiwyg .bottom-content .left-content h1 {
      color: #444;
      font-size: 1.8rem;
      font-family: "Josefin Sans", sans-serif;
      margin-bottom: 1rem; }
    body.page-template-page-profile .wysiwyg .bottom-content .left-content p {
      margin-top: 0;
      color: #444;
      line-height: 2rem; }
  body.page-template-page-profile .wysiwyg .bottom-content .featured-image {
    -ms-flex-preferred-size: 10rem;
    flex-basis: 10rem;
    -ms-flex-positive: 1;
    flex-grow: 1;
    min-width: 15rem; }
    body.page-template-page-profile .wysiwyg .bottom-content .featured-image img {
      width: 100%;
      height: auto; }

body.single-project article.wysiwyg {
  overflow: hidden; }
  @media (max-width: 71.25rem) {
    body.single-project article.wysiwyg {
      margin: 0 1rem; } }

body.single-project section.primary {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-top: 2rem; }
  body.single-project section.primary .project-description {
    -ms-flex-preferred-size: 40rem;
    flex-basis: 40rem;
    -ms-flex-positive: 1;
    flex-grow: 1;
    border-right: 1px solid #BEB7A8;
    padding-right: 2rem;
    margin-right: 2rem; }
    body.single-project section.primary .project-description p {
      color: #645c4a;
      line-height: 1.8rem;
      margin-bottom: 2rem; }
      @media (max-width: 910px) {
        body.single-project section.primary .project-description p {
          border: none; } }
  body.single-project section.primary .first-arch {
    -ms-flex-preferred-size: 15rem;
    flex-basis: 15rem;
    -ms-flex-positive: 1;
    flex-grow: 1; }
    body.single-project section.primary .first-arch img {
      width: 100%;
      max-width: 100%; }

body.single-project img.architectural-drawing {
  width: 100%;
  margin: 1rem 0; }

body.single-project .gallery-row {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -ms-flex-pack: center;
  justify-content: center;
  margin: 1rem -1rem;
  overflow: hidden; }
  body.single-project .gallery-row .gallery-image {
    height: 20rem;
    margin: 1rem;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    -ms-flex-preferred-size: 18rem;
    flex-basis: 18rem;
    -ms-flex-positive: 1;
    flex-grow: 1;
    max-width: 360px; }

body.login {
  background-color: #134282; }
  body.login div#login h1 a {
    background-image: url(../avatar.png);
    background-size: contain;
    background-position: center center;
    background-repeat: no-repeat;
    width: 300px;
    height: 100px; }
