body.page-template-page-images-sidebar,
body.page-template-page-philosophy {
	.content {
		display: flex;
		margin-top: 2rem;

		@media(max-width: $row-width) {
			padding: 0 2rem;
		}

		@media(max-width: 600px) {
			flex-wrap: wrap;
		}

		.featured-image {
			flex-basis: 10rem;
			flex-grow: 1;
			min-width: 15rem;

			@media (max-width: 600px) {
				order: 5;
			}

			img {
				width: 100%;
				height: auto;
			}
		}

		.right-content {
			flex-basis: 30rem;
			flex-grow: 1;
			padding-left: 2rem;
			border-left: 1px solid $color-light-brown;
			margin-left: 2rem;
			margin-bottom: 4rem;

			@media(max-width: 600px) {
				border-left: none;
				padding-left: 0;
				margin-left: 0;
				padding-top: 2rem;
			}
		}
	}

}